import LocationSectionUI from '../../../dictionaries/LocationSectionUI.dic';
import {
  sanitizeArr
} from '../../../lib/Array.lib';
import Core from "../../../lib/Core";
import Definition, {
  IN_OFFICE_REMOTE__FULL_WEEK_ID,
  IN_OFFICE_REMOTE__PART_WEEK_ID,
  IN_OFFICE_REMOTE__REMOTE_ONLY_ID,
  LOCATION__AMERICAS,
  LOCATION__CALIFORNIA,
  LOCATION__SAN_FRANCISCO_BAY_AREA,
  LOCATION__UNITED_STATES,
  LOC_TYPE__ALIAS_ID, LOC_TYPE__CITY_ID,
  LOC_TYPE__COUNTRY_ID,
  LOC_TYPE__METRO_AREA_ID,
  LOC_TYPE__METRO_AREA_SEC_ID,
  LOC_TYPE__MULTI_CONT_ID,
  LOC_TYPE__STATE_ID
} from "../../../lib/Definition";
import {
  NOT,
  YES
} from '../../../lib/GenericTools.lib';
import {
  joinKeyName
} from '../../../lib/String.lib';
import Box, {
  RefBox
} from '../../Layout/Wrappers/Box';
import Checkbox from '../../Layout/Wrappers/Checkbox';
import Fieldset from '../../Layout/Wrappers/Fieldset';
import Icon from '../../Layout/Wrappers/Icon';
import Menu from '../../Layout/Wrappers/Menu';
import Message, {
  WarningMessage
} from '../../Layout/Wrappers/Message';
import {
  PLACEMENT__TOP_START
} from '../../Layout/Wrappers/StyledTooltip';
import TextField from '../../Layout/Wrappers/TextField';
import Typography from '../../Layout/Wrappers/Typography';
import {
  CANDIDATE_SUMMARY__INCLUDE_FLAGS
} from '../SubmissionNotes.lib';
import {
  IncludeSubmissionCheckbox
} from './IncludeSubmissionCheckbox';

export default function LocationSection(props) {
  const {
    CandidateEditController = {}
  } = props;
  const {
    candidateLocations = [],
    inOfficeRemoteFlags = [],
    officeLocations = [],
    locationDetails = '',
  } = CandidateEditController.state;
  const locationTags = Definition.get("location");
  const inOfficeRemoteTags = Definition.get("inOfficeRemote");

  const isRemote = YES(
    inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__REMOTE_ONLY_ID)
  );

  const isHybrid = YES(
    inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__PART_WEEK_ID)
  );

  const isInOffice = YES(
    inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__FULL_WEEK_ID)
  );

  const isSomeOptionSelected = YES(
    isInOffice || isHybrid || isRemote
  );

  const isNotAllOptionsSelected = NOT(
    isInOffice && isHybrid && isRemote
  );

  return (
    <RefBox
      ref={(self) => Core.setKeyValue("location-section", self)}
      className='pb-2 m-1 outlined rounded bg-white-lighter'
    >

      <Fieldset
        title={LocationSectionUI.Fieldset.title}
        dot='orange'
      >

        <WarningMessage
          className='m-0'
          title={LocationSectionUI.WarningMessage.title}
          message={LocationSectionUI.WarningMessage.message}
        />

        {/** OPTIONS */}
        <Box row noWrap
          className='mt-2 mx-1'
        >
          {inOfficeRemoteTags.map((tag, index) => (
            <Checkbox
              key={
                joinKeyName([
                  'in_office_tag',
                  index,
                  tag.id,
                ])
              }
              label={tag.label}
              checked={inOfficeRemoteFlags.includes(tag.id)}
              onCheck={(ev, checked) => {
                CandidateEditController.setState(
                  {
                    inOfficeRemoteFlags: !!checked
                      ? [...inOfficeRemoteFlags, tag.id]
                      : inOfficeRemoteFlags.filter((n) => n !== tag.id)
                  },
                  () => {
                    if (
                      NOT(
                        CandidateEditController.state.inOfficeRemoteFlags.includes(
                          IN_OFFICE_REMOTE__FULL_WEEK_ID
                        ) ||
                        CandidateEditController.state.inOfficeRemoteFlags.includes(
                          IN_OFFICE_REMOTE__PART_WEEK_ID
                        )
                      )
                    ) {
                      CandidateEditController.setState({
                        officeLocations: []
                      });
                    }
                  }
                );
              }}
              className='mr-1'
            />
          ))}
        </Box>

        <Message
          className='m-0 mt-2'
          show={isSomeOptionSelected && isNotAllOptionsSelected}
          title={LocationSectionUI.InfoMessage.title}
          message={LocationSectionUI.InfoMessage.message}
        />

        <Box row
          acl={isSomeOptionSelected}
          className='mt-3 v-align-top'
        >

          {/** REMOTE */}
          <Box column w100 mr>
            <Box row noWrap className='mb-1'>
              <Icon outlined mr icon='home_pin' className='icon24' />
              <Typography blackDark bold mr>
                {LocationSectionUI.candidateLocations.title}
              </Typography>
              <Icon
                icon='help_outline'
                className='icon16 c-purple'
                title={LocationSectionUI.candidateLocations.help}
                placement={PLACEMENT__TOP_START}
              />
            </Box>
            <Menu tree multiple={false}
              layers={[
                LOC_TYPE__MULTI_CONT_ID,
                LOC_TYPE__COUNTRY_ID,
                LOC_TYPE__STATE_ID,
                LOC_TYPE__METRO_AREA_ID,
                LOC_TYPE__METRO_AREA_SEC_ID,
                LOC_TYPE__CITY_ID
              ]}
              treeDefaultExpandedIds={[
                LOCATION__AMERICAS,
                LOCATION__UNITED_STATES,
                LOCATION__CALIFORNIA,
                LOCATION__SAN_FRANCISCO_BAY_AREA
              ]}
              options={locationTags}
              value={candidateLocations[0]}
              onChange={(candidateLocation) => CandidateEditController.setState({
                candidateLocations: sanitizeArr([candidateLocation])
              })}
              placeholder={LocationSectionUI.candidateLocations.placeholder}
              className='mb-05'
            />
            <Typography sub error
              acl={NOT(candidateLocations.length)}
              className='ml-05'
            >
              {LocationSectionUI.candidateLocations.danger}
            </Typography>
          </Box>

          {/** IN OFFICE */}
          <Box column w100
            acl={isInOffice || isHybrid}
          >
            <Box row noWrap className='mb-1'>
              <Icon outlined mr icon='location_on' className='icon24' />
              <Typography blackDark bold mr>
                {LocationSectionUI.officeLocations.title}
              </Typography>
              <Icon
                icon='help_outline'
                className='icon16 c-purple'
                title={LocationSectionUI.officeLocations.help}
                placement={PLACEMENT__TOP_START}
              />
            </Box>
            <Menu tree
              layers={[
                LOC_TYPE__ALIAS_ID,
                LOC_TYPE__MULTI_CONT_ID,
                LOC_TYPE__COUNTRY_ID,
                LOC_TYPE__STATE_ID,
                LOC_TYPE__METRO_AREA_ID,
                LOC_TYPE__METRO_AREA_SEC_ID,
                LOC_TYPE__CITY_ID
              ]}
              treeDefaultExpandedIds={[
                LOCATION__AMERICAS,
                LOCATION__UNITED_STATES,
                LOCATION__CALIFORNIA,
                LOCATION__SAN_FRANCISCO_BAY_AREA
              ]}
              value={officeLocations}
              onChange={(officeLocations) => CandidateEditController.setState({
                officeLocations
              })}
              options={locationTags}
              className='mb-05'
            />
            <Typography sub error
              acl={NOT(officeLocations.length)}
              className='ml-05'
            >
              {LocationSectionUI.officeLocations.danger}
            </Typography>
          </Box>

        </Box>

        {/** NOTES */}
        <Box column
          acl={isSomeOptionSelected}
          className='mt-3'
        >
          <Box row noWrap w100 className='mb-1'>
            <Icon outlined mr icon='edit_note' className='icon24' />
            <Typography blackDark bold mr>
              {LocationSectionUI.locationDetails.title}
            </Typography>
            <Icon
              icon='help_outline'
              className='icon16 c-purple mr-auto'
              title={LocationSectionUI.locationDetails.help}
              placement={PLACEMENT__TOP_START}
            />
            <Box alignRight>
              <IncludeSubmissionCheckbox
                CandidateEditController={CandidateEditController}
                fieldId={CANDIDATE_SUMMARY__INCLUDE_FLAGS.locationDetails}
              />
            </Box>
          </Box>
          <TextField multiline small
            autoSize={{ minRows: 1, maxRows: 7 }}
            value={locationDetails}
            onChange={event =>
              CandidateEditController.setState({
                locationDetails: event.target.value
              })
            }
          />
        </Box>

      </Fieldset>

    </RefBox>
  );
}
