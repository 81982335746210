
const LocationSectionUI = {
  Fieldset: {
    title: 'Candidate location preferences'
  },
  InfoMessage: {
    title: 'Select All Options',
    message: 'Choose all the options that candidates are open to!'
  },
  WarningMessage: {
    title: 'Attention required',
    message: 'Select ALL options the candidate is open to.'
  },
  candidateLocations: {
    title: 'Home location',
    help: 'Please select candidate\'s home city (or state)',
    danger: 'Must choose home office location. Contact admin to add more locations',
    placeholder: 'Type or select city or metro area'
  },
  inOfficeRemoteFlags: {
    title: 'Desired Location Options: select all applicable'
  },
  officeLocations: {
    title: 'Office location(s)',
    help: (
      <>
        Select cities or regions the candidate can work in.<br />
        Contact recruiter@10by10.io to add a location if none apply.
      </>
    ),
    danger: 'Must choose desired office locations. Contact admin to add more locations'
  },
  locationDetails: {
    title: 'Notes',
    help: 'Notes on work location and flexibility to relocate'
  },
}
export default LocationSectionUI;