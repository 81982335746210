import {
  cloneDeep
} from 'lodash';
import {
  Arr,
  join
} from '../../../../lib/Array.lib';
import { NOT, YES } from '../../../../lib/Boolean.lib';
import {
  fetchCandidateResumeText
} from '../../../../lib/Candidate';
import {
  Obj
} from '../../../../lib/Object.lib';
import {
  trim
} from '../../../../lib/String.lib';
import evalVisaMatch from '../../../Jobs/Edit/Forms/MustHave/Elements/MatchProperties/evalVisaMatch.fun';
import getLocationMatches from '../../../Jobs/Edit/Forms/MustHave/Elements/MatchProperties/getLocationMatches.fun';
import {
  evaluateChip
} from './Chip.lib';
import {
  evaluateChipGroup
} from './ChipGroup.lib';
import {
  evaluateChipTag
} from './ChipTag.lib';
import {
  evaluateComplexHaves
} from './Complex.lib';
import {
  HAVE__MAYBE__KEY,
  HAVE__NOT_MET__KEY,
  HAVE__YES_MET__KEY,
  KEY__MATCH_HAVES__REJECTION,
  MUST_HAVE_KEY,
  NICE_TO_HAVE_KEY
} from './Generators.lib';

export function getFixedHaves(complexHaves) {
  complexHaves = Arr(cloneDeep(Obj(complexHaves)).args);
  return complexHaves;
}

export async function evaluateHaves({
  job = {},
  candidate = {},
  edit = null,
}) {
  const results = {
    required: {
      not: [],
      yes: [],
      maybe: [],
    },
    desirable: {
      not: [],
      yes: [],
      maybe: [],
    }
  }
  const isEditingRejection = (edit === KEY__MATCH_HAVES__REJECTION);
  const mustHave = getFixedHaves(job.mustHave);
  const niceToHave = getFixedHaves(job.niceToHave);
  await fetchCandidateResumeText({ candidate });
  const _pushResult = ({ source, have }) => {
    switch (have.match) {
      // NO MET
      case false:
        have.met = HAVE__NOT_MET__KEY;
        results[source].not.push(have);
        break;

      // YES MET
      case true:
        have.met = HAVE__YES_MET__KEY;

        // IF rejection is editing then ignore
        have.ignore = isEditingRejection;

        results[source].yes.push(have);
        break;

      // MAYBE MET
      default:
        have.met = HAVE__MAYBE__KEY;
        results[source].maybe.push(have);
        break;
    }
  }
  const _classifyResult = (source) => (have) => {
    have.source = source;
    evaluateSingleHave({ have, candidate });
    _pushResult({ source, have });
  }
  const haveYoE = {
    type: 'yearsOfExperience',
    value: candidate._yearsOfExperienceForCalc,
    atLeast: job.minYearsOfExperience,
    match: evaluateYoE({ job, candidate })
  };
  const locationMatching = getLocationMatches({
    job,
    candidate
  });
  const haveYesLocations = {
    type: 'locations',
    value: join(locationMatching.yes),
    match: YES(locationMatching.yes.length)
  };
  const haveNoLocations = {
    type: 'locations',
    value: join(locationMatching.not),
    match: NOT(locationMatching.not.length)
  };
  const haveVisa = {
    type: 'visa',
    value: candidate._visa,
    match: evalVisaMatch({
      job,
      candidate
    })
  };
  _pushResult({ source: MUST_HAVE_KEY, have: haveYoE });
  if (locationMatching.yes.length) {
    _pushResult({ source: MUST_HAVE_KEY, have: haveYesLocations });
  }
  else if (locationMatching.not.length) {
    _pushResult({ source: MUST_HAVE_KEY, have: haveNoLocations });
  }
  _pushResult({ source: MUST_HAVE_KEY, have: haveVisa });
  if (
    // Match IF...
    // candidate.minimumSalary AND job.salaryMax ARE greater THAN 0.
    (+candidate.minimumSalary && +job.salaryMax)
  ) {
    const haveSalary = {
      type: 'salary',
      value: candidate.minimumSalary,
      atLeast: job.salaryMax,
      match: (
        // AND candidate.minimumSalary IS equals OR smaller THAN job.salaryMax
        (candidate.minimumSalary <= job.salaryMax)
      )
    };
    _pushResult({ source: MUST_HAVE_KEY, have: haveSalary });
  }
  mustHave.forEach(_classifyResult(MUST_HAVE_KEY));
  niceToHave.forEach(_classifyResult(NICE_TO_HAVE_KEY));
  console.debug('evaluateHaves :MH :NH :RES\n', mustHave, niceToHave, results);
  return results;
}

export function evaluateYoE({ job, candidate }) {
  const minYearsOfExperience = parseFloat(job.minYearsOfExperience);
  const yearsOfExperience = parseFloat(candidate._yearsOfExperienceForCalc);
  if (!minYearsOfExperience || !yearsOfExperience) { return null; }
  const diff = minYearsOfExperience - yearsOfExperience;
  return (diff <= 0);
}

export function evaluateSingleHave({ have, candidate }) {
  if (!!have.args && !!have.args.length) {
    return have.match = evaluateComplexHaves({ have, candidate });
  }
  else if (have.type === 'chipGroup') {
    return have.match = evaluateChipGroup({ have, candidate });
  }
  else if (have.type === 'chip') {
    return have.match = evaluateChip({ have, candidate });
  }
  else if (have.type === 'chipTag') {
    return have.match = evaluateChipTag({ have, resumeText: trim(candidate._resumeText) });
  }
  // Return null for text or undefined types
  return have.match = null;
}

export function getEvaluationChipStyle(result) {
  let style = {};
  if (result === false) {
    style.border = "2px solid red";
  } else if (result === true) {
    style.border = "1px solid green";
  } else {
    style.border = "1px solid gray";
  }
  return style;
}