import {
  OPTION_YES
} from '../../lib/Constants';
import Core from '../../lib/Core';
import {
  toLinkedinDateString
} from '../../lib/services/LinkedIn/LinkedIn.lib';
import {
  ErrorChipMessage
} from '../Layout/Wrappers/Message';

const DEBUG_LINKEDIN_DATE_FORMAT = Core.debug('DEBUG_LINKEDIN_DATE_FORMAT');

export default function LinkedinFormattedDate({
  startDate,
  startDateRequired = true,
  startDateYear,
  startDateMonth,
  endDate,
  endDateRequired = true,
  endDateYear,
  endDateMonth,
  durationYears,
  durationMonthsModule,
  currentlyEmployed,
  className
}) {
  DEBUG_LINKEDIN_DATE_FORMAT && console.debug(
    'LinkedinFormattedDate:in\n',
    {
      startDate,
      startDateYear,
      startDateMonth,
      endDate,
      endDateYear,
      endDateMonth,
      durationYears,
      durationMonthsModule,
      currentlyEmployed
    }
  );
  let present = (
    endDateYear
      ? endDateMonth
        ? toLinkedinDateString({ currentDate: true })
        : toLinkedinDateString({ currentYear: true })
      : toLinkedinDateString()
  );
  startDate = (
    startDateYear
      ? toLinkedinDateString({ month: startDateMonth, year: startDateYear })
      : toLinkedinDateString({ date: startDate })

  );
  endDate = (
    ((currentlyEmployed === OPTION_YES) || (present === endDate))
      ? 'Present'
      : endDateYear
        ? toLinkedinDateString({ month: endDateMonth, year: endDateYear })
        : toLinkedinDateString({ date: endDate })
  );
  DEBUG_LINKEDIN_DATE_FORMAT && console.debug(
    'LinkedinFormattedDate:out\n',
    {
      startDate,
      endDate,
      present,
      durationYears,
      durationMonthsModule,
      currentlyEmployed
    }
  );
  const LESS_THAN_A_YEAR = 'Less than a year · ';
  return (
    <small className={className || 'c-black-medium'}>
      {!(startDate || endDate)
        ? null
        : startDate === endDate
          ? `${LESS_THAN_A_YEAR}${startDate}`
          : (
            <>
              {(durationYears === 0) && (durationMonthsModule === 0) && LESS_THAN_A_YEAR}
              {durationYears ? `${durationYears} yr${durationYears === 1 ? '' : 's'}` : ''}
              {durationMonthsModule ? ` ${durationMonthsModule} mo${durationMonthsModule === 1 ? '' : 's'}` : ''}
              {durationYears || durationMonthsModule ? ' · ' : ''}
              {
                (
                  !startDate && startDateRequired
                ) ? (
                  <ErrorChipMessage
                    title='Edit item to correct this'
                    className='mr-1'
                  >
                    An start date is required
                  </ErrorChipMessage>
                ) : (
                  startDate
                )
              }
              {
                (
                  !endDate && endDateRequired
                ) ? (
                  <ErrorChipMessage title='Edit item to correct this'>An end date is required</ErrorChipMessage>
                ) : (
                  ` - ${endDate}`
                )
              }
            </>
          )
      }
    </small>
  );
}
