import Company, {
  evalDefaultPosition,
  fixCompanyName,
  getCompany,
  getCompanyDuplicates,
  getPositionId,
  saveCompany
} from '../../../lib/Company';
import Core from '../../../lib/Core';
import CrunchbaseLib from '../../../lib/Crunchbase.lib';
import School, {
  evalDefaultDegree,
  fixSchoolName,
  getDegreeId,
  getSchool,
  getSchoolDuplicates,
  getSchoolSignals,
  saveSchool
} from '../../../lib/School';
import {
  setCandidateEmployer,
  setCandidateSchool,
  unsetCandidateEmployer,
  unsetCandidateSchool
} from '../../../lib/models/candidate';
import {
  MODEL_NAME__COMPANY, MODEL_NAME__COMPANY_PLURAL
} from '../../../lib/models/company.model';
import {
  getEducationModel
} from '../../../lib/models/education.model';
import getEmploymentModel from '../../../lib/models/employment.model';
import {
  MODEL_NAME__SCHOOL,
  MODEL_NAME__SCHOOL_PLURAL
} from '../../../lib/models/school.model';
import {
  COLLECTION__CANDIDATES,
  readLoopbackRecord
} from '../../../lib/services/BE/loopback.api';
import Box from '../../Layout/Wrappers/Box';
import {
  WarningMessage
} from '../../Layout/Wrappers/Message';
import {
  filterHistoriesWithTroubles,
  sortDegrees,
  sortEducationHistories,
  sortEmploymentHistories,
  sortPositionHistories
} from '../CandidateHistories.lib';
import EducationDegree from '../Forms/EducationDegree';
import ExperiencePositionHistory from '../Forms/ExperiencePositionHistory';
import {
  autolinkCompany
} from '../Forms/HistoryName';

const HISTORIES_DERIVATOR = {
  experience: {
    prefixSectionKey: 'work ',
    sectionKey: 'experience',
    labelSectionTitle: 'Experience',
    labelMostRelevantTitle: 'Current or most recent position',
    entityModelKey: 'company',
    entityModelKeyPlural: 'companies',
    entityModelName: MODEL_NAME__COMPANY,
    entityModelNamePlural: MODEL_NAME__COMPANY_PLURAL,
    entityIdKey: 'companyId',
    entityNameKey: 'name',
    historiesKey: 'employmentHistories',
    historyKey: 'employment',
    historyNameKey: 'employerOrgName',
    pieceKey: 'position',
    piecesKey: 'positionHistories',
    pieceNameKey: 'title',
    getHistoryModel: getEmploymentModel,
    setRelevantEntity: ({ candidate, history, piece, option }) => setCandidateEmployer({
      candidate,
      employment: option ? option.employment : history,
      position: option ? option.position : piece
    }),
    unsetRelevantEntity: ({ candidate, history, piece }) => unsetCandidateEmployer({
      candidate,
      employment: history,
      position: piece
    }),
    getRelevantEntity: ({ candidate }) => getPositionId({
      employerOrgName: candidate.currentEmployer,
      title: candidate.currentTitle
    }),
    evalRelevantEntity: ({ candidate, history, piece }) => evalDefaultPosition({
      candidate,
      employment: history,
      position: piece
    }),
    getHistoryPieceId: ({ historyName, piece }) => getPositionId({
      employerOrgName: historyName,
      title: piece.title
    }),
    sortHistories: sortEmploymentHistories,
    sortHistoryPieces: ({ candidate, history, a, b }) => sortPositionHistories({
      candidate, employment: history, a, b
    }),
    getEntityDuplicates: getCompanyDuplicates,
    postEntity: Company.post,
    getEntity: getCompany,
    saveEntity: saveCompany,
    getSignals: Company.getSignals,
    fixEntityName: async ({ entity, organization }) => await fixCompanyName({ company: entity, organization }),
    autoLinkEntity: async ({ entityName }) => autolinkCompany({ employerOrgName: entityName }).then(response => ({
      entity: response.company,
      organization: response.organization
    })),
    filterHistoryPiecesWithTroubles: ({ pieces }) => filterHistoriesWithTroubles({
      histories: pieces
    }),
    HistoryPieceEdit: ExperiencePositionHistory
  },
  education: {
    prefixSectionKey: '',
    sectionKey: 'education',
    labelSectionTitle: 'Education',
    labelMostRelevantTitle: 'Most relevant degree',
    entityModelKey: 'school',
    entityModelKeyPlural: 'schools',
    entityModelName: MODEL_NAME__SCHOOL,
    entityModelNamePlural: MODEL_NAME__SCHOOL_PLURAL,
    entityIdKey: 'schoolId',
    entityNameKey: 'universityName',
    historiesKey: 'educationHistories',
    historyKey: 'education',
    historyNameKey: 'schoolName',
    piecesKey: 'degrees',
    pieceKey: 'degree',
    pieceNameKey: 'degreeName',
    getHistoryModel: getEducationModel,
    setRelevantEntity: ({ candidate, history, piece, option }) => setCandidateSchool({
      candidate,
      education: option ? option.education : history,
      degree: option ? option.degree : piece
    }),
    unsetRelevantEntity: ({ candidate, history, piece }) => unsetCandidateSchool({
      candidate,
      education: history,
      degree: piece
    }),
    getRelevantEntity: ({ candidate }) => getDegreeId({
      schoolName: candidate.undergraduateSchool,
      degreeName: candidate._undergraduateDegree,
      degreeMajor: candidate.undergraduateMajor
    }),
    evalRelevantEntity: ({ candidate, history, piece }) => evalDefaultDegree({
      candidate,
      education: history,
      degree: piece
    }),
    getHistoryPieceId: ({ historyName, piece }) => getDegreeId({
      schoolName: historyName,
      degreeName: piece.degreeName,
      degreeMajor: piece.degreeMajor
    }),
    sortHistories: sortEducationHistories,
    sortHistoryPieces: ({ candidate, history, a, b }) => sortDegrees({
      candidate, education: history, a, b
    }),
    getEntityDuplicates: getSchoolDuplicates,
    postEntity: School.post,
    getEntity: getSchool,
    saveEntity: saveSchool,
    getSignals: getSchoolSignals,
    fixEntityName: async ({ entity, organization }) => await fixSchoolName({ school: entity, organization }),
    autoLinkEntity: async ({ entityName }) => {
      let school = await getSchool({ name: entityName });
      let organization;
      if (school) {
        if (school.crunchbaseOrganizationId) {
          organization = await CrunchbaseLib.get({
            where: { id: school.crunchbaseOrganizationId },
          }).then((organizations) => organizations[0]);
        }
        school = await fixSchoolName({ school, organization });
      }
      return {
        entity: school,
        organization
      }
    },
    filterHistoryPiecesWithTroubles: ({ pieces }) => filterHistoriesWithTroubles({
      histories: pieces,
      evalDates: history => false
    }),
    HistoryPieceEdit: EducationDegree
  }
};
export default HISTORIES_DERIVATOR;

export async function deleteEntity({
  context = { ...HISTORIES_DERIVATOR.experience },
  entityId,
  candidateId
}) {
  const {
    prefixSectionKey,
    sectionKey,
    entityModelKey,
    entityModelKeyPlural,
    entityModelName = MODEL_NAME__COMPANY
  } = context;
  const isForCompany = (entityModelName === MODEL_NAME__COMPANY);
  return new Promise(async (resolve, reject) => {
    const whereKey = isForCompany
      ? "employmentHistories.companyId"
      : "educationHistories.schoolId";
    let candidates = await readLoopbackRecord(
      {
        collection: COLLECTION__CANDIDATES,
        where: { [whereKey]: { $in: [entityId] } },
        fields: ['id']
      }
    )
    if (!!candidates.length && candidateId) {
      candidates = candidates.filter(c => c.id !== candidateId);
    }
    Core.showConfirm({
      title: 'Warning',
      content: (
        <Box column>
          <WarningMessage className='m-0 w-100'>
            {`CAUTION - Remove ${entityModelKey} can not be undone.`}
          </WarningMessage>
          {!!candidates.length
            ? (
              <>
                <div className='mt-2'>
                  {`There are ${candidates.length} other candidates have links to this ${entityModelKey} in their ${prefixSectionKey}${sectionKey} and will be unlinked.`}
                </div>
                <div className='mt-2'>
                  {`Signals for other candidates linked to this ${entityModelKey} will not be changed. When these candidates' records are edited again, we will look up signals associated with all ${entityModelKeyPlural}. If this ${entityModelKey} was deleted, no signals will be associated with the this ${entityModelKey} when Edit Candidate is SAVED.`}
                </div>
              </>
            )
            : (
              <div className='mt-1'>
                {`No candidate is linked to this ${entityModelKey}, it's safe to delete this ${entityModelKey}.`}
              </div>
            )}
        </Box>
      ),
      async onAccept() {
        let response = await (isForCompany ? Company : School).delete(entityId).catch(Core.showError);
        if (response?.count === 1) { resolve(); }
        else { Core.showError(`Entity(${entityId}) can not be deleted`); }
      },
      onAcceptLabel: 'Proceed to delete',
      paperStyle: {
        maxWidth: '600px'
      }
    });
  });
}
