import {
  Accordion,
  Icon
} from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import {
  cloneDeep
} from 'lodash';
import {
  useState
} from 'react';
import {
  PRESCREEN_QUESTIONS__ASKED_BY__EMPLOYER,
  PRESCREEN_QUESTIONS__ASKED_BY__TEN_BYE_TEN
} from '../../dictionaries/PrescreenQuestionsSectionUI.dic';
import {
  Arr,
  join
} from '../../lib/Array.lib';
import Core from '../../lib/Core';
import useStateHook from '../../lib/hooks/useState.hook';
import Job from '../../lib/Job';
import {
  Obj
} from '../../lib/Object.lib';
import {
  joinKeyName
} from '../../lib/String.lib';
import {
  matchLocation
} from '../../lib/URL.lib';
import {
  CandidateEditController
} from '../Candidates/Edit/CandidateEdit';
import NoteToAccountManagerFieldset from '../Candidates/Forms/NoteToAccountManagerFieldset';
import ReactDialog from '../Forms/ReactDialog';
import Box from '../Layout/Wrappers/Box';
import Card from '../Layout/Wrappers/Card';
import Checkbox from '../Layout/Wrappers/Checkbox';
import {
  Html
} from '../Layout/Wrappers/Html';
import {
  WarningChipMessage
} from '../Layout/Wrappers/Message';
import Once from '../Layout/Wrappers/Once';
import TextField from '../Layout/Wrappers/TextField';
import Title from '../Layout/Wrappers/Title';
import { Label } from '../Layout/Wrappers/Typography';
import {
  generateMatchHavesHTMLString
} from '../Match/Haves/v2/Generators.lib';
import {
  PRESCREEN_QUESTIONS_CONFIG
} from './PrescreenQuestions.config';
import {
  fetchQuestionFromBank,
  mapQuestionsAnswers,
  QUESTION_ANSWER__SOURCE__EMPLOYER,
  QUESTION_ANSWER__SOURCE__GLOBAL,
  QUESTION_ANSWER__SOURCE__JOB
} from './PrescreenQuestions.lib';

export default function AnswerJobsQuestions(props) {
  let {
    name,
    onClose, // close
    onAcceptLabel, // nxtBtnText
    onCancelLabel, // cancelBtnText
    onAccept = ({ jobsPermittedAnswers }) => (event) => null, // handlerNext
    onCancel = ({ jobsPermittedAnswers = initialState }) => (event) => null, // handlerNextOnCancel
    onChange = update => null, // finalUpdater
    candidate = {},
    jobIds = [],
    extraComponentStructure,
    reference = self => null
  } = props;
  const [state, updateState] = useStateHook();

  let {
    initialState,
    jobId: filterJobId,
    globalQuestions,

    // {object[]} - jobs
    jobs = [],

  } = state;
  const _fetchJobs = async (options = {}) => {
    await Job.getWhere({
      id: {
        inq:
          options.jobIds ? options.jobIds : filterJobId
            ? Arr(jobIds).filter(jobId => jobId !== filterJobId)
            : Arr(jobIds)
      }
    }).then(async jobs => await updateState({ jobs }));
  }
  const _fetchGlobalQuestions = async () => {
    await updateState({ globalQuestions: Arr(await fetchQuestionFromBank()) });
  }
  let {
    globalAnswers = {},
    employerAnswers = {},
    jobAnswers = {},
  } = mapQuestionsAnswers({
    globalQuestions,
    candidate,
    jobs
  });

  // console.debug('state', state);
  // console.debug('jobsPermittedAnswers', candidate.jobsPermittedAnswers);
  // console.debug('globalAnswers', globalAnswers);
  // console.debug('employerAnswers', employerAnswers);
  // console.debug('jobAnswers', jobAnswers);

  const YES__DISPLAY_HAVES = (
    matchLocation(/candidate\/(edit|create)/i) &&
    jobs[0]?.id
  );

  const _onChange = source => entityId => async update => {
    candidate.jobsPermittedAnswers = {
      ...Obj(candidate.jobsPermittedAnswers),
      globalAnswers: {
        ...Obj(candidate.jobsPermittedAnswers?.globalAnswers), ...globalAnswers
      },
      employerAnswers: {
        ...Obj(candidate.jobsPermittedAnswers?.employerAnswers), ...employerAnswers
      },
      jobAnswers: {
        ...Obj(candidate.jobsPermittedAnswers?.jobAnswers), ...jobAnswers
      },
    };
    await onChange({
      jobsPermittedAnswers: candidate.jobsPermittedAnswers,
      key: 'jobsPermittedAnswers'
    });
  };

  return (
    <ReactDialog
      name={`${name}__answer_jobs_questions`}
      reference={(self) => {
        reference({
          async open({ jobIds } = {}) {
            await updateState({
              initialState: cloneDeep(candidate.jobsPermittedAnswers)
            });
            await _fetchJobs({ jobIds });
            await _fetchGlobalQuestions();
            await self.open();
          },
          async close() {
            await self.close();
          },
        });
      }}
      title='Check Role Fit and Complete Application Questions'
      content={
        <Box row
          className='flex-align-center-top'
          style={{
            maxWidth: '100%'
          }}
        >
          {
            YES__DISPLAY_HAVES && (
              <Box column flex1
                className='mt-05 mr-1 w-50'
              >
                <Title>
                  AI Evaluation
                </Title>
                <Once
                  name={joinKeyName([
                    'PrescreenQuestions',
                    'MatchHaves',
                    jobs[0].id
                  ])}
                  promise={async () => await generateMatchHavesHTMLString({
                    job: jobs[0],
                    candidate: CandidateEditController().state
                  })}
                  render={function IconInfo(props) {
                    return (
                      <Card
                        className='p-1'
                      >
                        <Html
                          value={props.result}
                          className='mt-1'
                          style={{ maxWidth: '100%' }}
                        />
                        <NoteToAccountManagerFieldset
                          title='Private Note to 10x10'
                          subtitle='Share insights or corrections to the AI evaluation'
                        />
                      </Card>
                    );
                  }}
                />
              </Box>
            )
          }
          <Box column flex1
            className='w-50'
          >
            <Title>
              Screening Questions
            </Title>
            <GlobalAnswers
              answers={globalAnswers}
              onChange={_onChange(QUESTION_ANSWER__SOURCE__GLOBAL)}
            />
            <AnswersList
              entityAnswers={jobAnswers}
              onChange={_onChange(QUESTION_ANSWER__SOURCE__JOB)}
              employerAnswers={employerAnswers}
              onChangeEmployer={_onChange(QUESTION_ANSWER__SOURCE__EMPLOYER)}
            />
            {extraComponentStructure && (
              <div className='mt-3'>
                {extraComponentStructure}
              </div>
            )}
          </Box>
        </Box>
      }
      onAcceptLabel={onAcceptLabel}
      onAccept={onAccept({
        jobsPermittedAnswers: {
          ...Obj(candidate.jobsPermittedAnswers),
          globalAnswers: {
            ...Obj(candidate.jobsPermittedAnswers?.globalAnswers), ...globalAnswers
          },
          employerAnswers: {
            ...Obj(candidate.jobsPermittedAnswers?.employerAnswers), ...employerAnswers
          },
          jobAnswers: {
            ...Obj(candidate.jobsPermittedAnswers?.jobAnswers), ...jobAnswers
          },
        }
      })}
      onCancelLabel={onCancelLabel}
      onCancel={onCancel({ jobsPermittedAnswers: initialState })}
      onClose={onClose}
      paperStyle={{ width: 1600 }}
    />
  );
}

function GlobalAnswers(props) {
  let { answers } = props;
  return !!Object.values(answers).length && (
    <SingleCard
      title={PRESCREEN_QUESTIONS_CONFIG.globalAnswers.title}
      subtitle={PRESCREEN_QUESTIONS_CONFIG.globalAnswers.subtitle}
      answers={answers}
      {...props}
    />
  );
}

function AnswersList(props) {
  let { entityAnswers } = props;
  let list = Object.values(entityAnswers);
  return list.map((item) => (
    <SingleCard
      key={`entity_answers-${item.title}`}
      title={item.title}
      answers={item.answers}
      entityId={item.id}
      employerId={item.employerId}
      {...props}
    />)
  );
}

function SingleCard(props) {
  let { title, subtitle, answers, entityId, onChange, employerId, employerAnswers, onChangeEmployer } = props;
  let [expanded, setExpended] = useState(true);
  return !!Object.values(answers).length && (
    <Accordion
      expanded={!!expanded}
      onChange={(event) => setExpended(!expanded)}
      className='my-05'
    >
      <AccordionSummary expandIcon={<Icon>expand_more</Icon>}>
        <div>
          <Typography className='fw-600'>{title}</Typography>
          <div className='f-md c-black-medium'>{subtitle}</div>
        </div>
      </AccordionSummary>
      <AccordionDetails style={{ display: 'inline-block', width: '100%' }}>
        {employerId && <QuestionsList {...props} answers={Obj(employerAnswers)[employerId]?.answers} onChange={onChangeEmployer(employerId)} />}
        <QuestionsList {...props} answers={answers} onChange={onChange(entityId)} />
      </AccordionDetails>
    </Accordion>
  );
}

function QuestionsList(props) {
  let { answers } = props;
  return Object.values(answers).sort((a, b) => a.order - b.order).map(answer => {
    return (
      <SingleQuestion
        key={`answers_jobs_question_list_${answer.question}-${answer.answer}`}
        answer={answer}
        {...props}
      />
    );
  });
}

function SingleQuestion(props) {
  let { answer, onChange = () => null } = props;
  let [_answer, _setAnswer] = useState(answer.answer);
  return (
    <Box column
      className='p-0 mt-1'
    >

      {/** QUESTION (title and subtitle plus action) */}
      <Box row noWrap spaceBetween flex1 className='flex-align-left-top'>

        {/** QUESTION and INSTRUCTION (title and subtitle) */}
        <Box column flex1>
          {
            (
              answer.question
            ) ? (
              <Box row noWrap flex1 className='flex-align-left-top'>
                {Core.isAdmin() && answer.answerMandatory && <b className={`c-red mr-1`}>*</b>}
                <Label className='tt-none wrap'>
                  {join([
                    Core.isAdmin() && answer.askedBy && (
                      `[${answer.askedBy === PRESCREEN_QUESTIONS__ASKED_BY__TEN_BYE_TEN.id
                        ? PRESCREEN_QUESTIONS__ASKED_BY__TEN_BYE_TEN.label
                        : (answer.askedBy === PRESCREEN_QUESTIONS__ASKED_BY__EMPLOYER.id
                        ) ? PRESCREEN_QUESTIONS__ASKED_BY__EMPLOYER.label
                          : null
                      }]`
                    ),
                    answer.question
                  ], ' ')}
                </Label>
              </Box>
            ) : (
              <WarningChipMessage>
                <div className='statement'>
                  {answer.questionError || 'Missing question'}
                </div>
              </WarningChipMessage>
            )
          }
          <Typography sub className='c-black-medium f-sm fw-400 wrap'>
            {answer.instruction}
          </Typography>
        </Box>

        {/** ACTION BAR */}
        <Checkbox
          checked={!!answer.autoIncludeInWriteup}
          onChange={event => {
            answer.autoIncludeInWriteup = !answer.autoIncludeInWriteup;
            onChange(answer);
          }}
          labelProps={{ className: 'c-black-medium f-sm nowrap' }}
          wrapperProps={{ size: 'small' }}
          checkboxProps={{ size: 'small' }}
        >
          Include in submission
        </Checkbox>

      </Box>

      {/** ANSWER (input) */}
      <TextField multiline disabledBlurExport
        variant='standard'
        placeholder='Write your answer here'
        value={_answer}
        onChange={(event) => _setAnswer(event.target.value)}
        onBlur={(event) => {
          answer.answer = _answer;
          onChange(answer);
        }}
        maxRows={5}
      />

    </Box>
  );
}
