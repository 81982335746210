/** @todo [ 2022-12-20 ][ MS: standardize parameters as CONSTANT format ] */

import { CANDIDATE_UI } from '../../components/Candidates/CandidateUI.dic';
import { trim } from '../String.lib';


/** @ref https://app.shortcut.com/10by10/story/7552 */

export const TEMPLATE__CANDIDATE_SUBMISSION__EMAIL_SUBJECT = '{{SUBJECT_PREFIX}} SUBMISSION | {{CANDIDATE_FULLNAME}} | {{EMPLOYER_NAME}} - {{JOB_TITLE}}';

export const TEMPLATE__CANDIDATE_SUBMISSION__EMAIL_BODY = (`

  <!-- TEMPLATE__CANDIDATE_SUBMISSION__EMAIL_BODY_INTRODUCTION -->
  {{{INTRODUCTION}}}

  <!-- Automated generated content (TEMPLATE__CANDIDATE_SUBMISSION__SUBMISSION_NOTES) -->
  {{{SUBMISSION_NOTES}}}

  <!-- TEMPLATE__CANDIDATE_SUBMISSION__EMAIL_BODY_CLOSING -->
  {{{CLOSING}}}
`);

export const TEMPLATE__CANDIDATE_SUBMISSION__EMAIL_BODY_INTRODUCTION = (`
  <!-- SECTION 1: Introduction -->
  <p>
    To interview {{CANDIDATE__FULLNAME}}, email them directly with a calendar link (CC <a href="mailto:recruiter@10by10.io">recruiter@10by10.io</a>) to avoid scheduling back and forth.
  </p>
`);

export const TEMPLATE__CANDIDATE_SUBMISSION__EMAIL_BODY_CLOSING = (`
  <!-- SECTION 7: Closing -->
  <p>
    If rejection, quick feedback -
  </p>
  <ol>
    <li>
      Work history - company size | industry
    </li>
    <li>
      Level - too junior, too senior
    </li>
    <li>
      Technical experience
    </li>
    <li>
      Has stronger candidates in the pipeline
    </li>
    <li>
      Other - why
    </li>
  </ol>
  {{{SIGNATURE}}}
`);

export const TEMPLATE__CANDIDATE_SUBMISSION__SUBMISSION_NOTES = (`

  <!-- SECTION 2.1: Automated generated message -->
  <p>
    <b>{{#if _subjectPrefix}}{{_subjectPrefix}}&nbsp;{{/if}}
    Summary:</b> generated by resume parser, external data, recruiter input
  </p>

  <!-- SECTION 2.2: Tag line -->
  {{#if TAG_LINE}}
    <p>
      {{{TAG_LINE}}}
    </p>
  {{/if}}

  <!-- SECTION 2.3: Automated generated message -->
  <!-- Automated generated message (TEMPLATE__CANDIDATE_SUBMISSION__CANDIDATE_NOTES) -->
  {{{CANDIDATE_NOTES}}}
  
  <!-- SECTION 4: Answered prescreen questions -->
  {{#if JOB_SUBMISSION_NOTES}}
    <p>
      <b>Recruiter prescreen summary:</b>
    </p>
    {{{JOB_SUBMISSION_NOTES}}}
  {{/if}}
  
  <!-- SECTION 5: Recruiter's original write up (deprecated)(story_7865) -->

  <!-- SECTION 6: 10x10 write up -->
  {{#if SUBMISSION_NOTES_TO_EMPLOYER}}
    <p><br /></p>
    <blockquote>
      {{{SUBMISSION_NOTES_TO_EMPLOYER}}}
    </blockquote>
  {{/if}}

`);

export const TEMPLATE__CANDIDATE_SUBMISSION__CANDIDATE_NOTES = (`
  {{#if CANDIDATE__NICKNAME}}
    <p>
      Note - Candidate prefers to be addressed by {{CANDIDATE__NICKNAME}}.
    </p>
  {{/if}}
  {{#if WORK_EXPERIENCE}}{{{WORK_EXPERIENCE}}}{{/if}}
  {{#if candidate.linkedInURL}}
    <ul>
      <li>
        <b>LinkedIn</b>:&nbsp;
        <a href={{candidate.linkedInURL}}>
          {{candidate.linkedInURL}}
        </a>
      </li>
    </ul>
  {{/if}}
  {{#if POSITIVE_SIGNALS}}
    <ul>
      <li>
        We like:&nbsp;
        <b>{{{POSITIVE_SIGNALS}}}</b>
      </li>
    </ul>
  {{/if}}
  <ul>
    {{{TECH_SKILLS}}}
    {{{YEARS_OF_EXPERIENCE}}}
    {{#if MOST_RECENT}}
      <li>Most recent: {{MOST_RECENT}}</li>
    {{/if}}
    {{{LEVEL}}}
    {{{GITHUB_STATS}}}
    {{{STACK_OVERFLOW}}}
    <!-- @todo [ 2023-03-16 ][ MS: I guessing next if/else blocks are outdated/deprecated ] -->
    {{#if candidate.currentJobTitle}}
      {{#if candidate.currentPositionCompany}}
        <li>
          Current Title/Company:&nbsp;
          {{candidate.currentJobTitle}}
          @
          {{candidate.currentPositionCompany}}
        </li>
      {{else}}
        <li>
          Current Title/Company:&nbsp;
          {{candidate.currentJobTitle}}
        </li>
      {{/if}}
    {{else if candidate.currentPositionCompany}}
      <li>
        Current Title/Company:&nbsp;@
        {{candidate.currentPositionCompany}}
      </li>
    {{/if}}
    {{#if candidate.___normalCollegeFlow}}
      {{{candidate.___normalCollegeFlow}}}
    {{/if}}
    {{#if CANDIDATE__TECH_SKILLS}}
      <li>
        <b>Tech</b>:&nbsp;
        {{CANDIDATE__TECH_SKILLS}}
      </li>
    {{/if}}
    {{#if CANDIDATE__FLAGS__IN_OFFICE_REMOTE}}
      <li>
        Location preference: <b>{{CANDIDATE__FLAGS__IN_OFFICE_REMOTE}}</b>
        {{#if CANDIDATE__PREFERRED_LOCATIONS}}
          &nbsp;(from: {{CANDIDATE__PREFERRED_LOCATIONS}})
        {{/if}}
        {{#if CANDIDATE__LOCATION_DETAILS}}
          , {{CANDIDATE__LOCATION_DETAILS}}.
        {{/if}}
      </li>
    {{/if}}
    {{#if candidate.___isNotVisaUnknown}}
      <li>
        Work Authorization:&nbsp;
        <b>{{VISA}}</b>
      </li>
    {{/if}}
    {{#if candidate.jobHopReason4Emp}}
      <li>
        Frequent job change explanation:&nbsp;
        {{candidate.jobHopReason4Emp}}
      </li>
    {{/if}}
    {{#if INCLUDE_MINIMUM_SALARY}}
      <li>
        Base salary expectation:
        &nbsp;~&nbsp;{{MINIMUM_SALARY}}
        &nbsp;({{#if SALARY_CONFIDENCE}}
        {{SALARY_CONFIDENCE}}&nbsp;-&nbsp;{{/if}}please verify with candidate)
        {{#if candidate.salaryNote}}
          &nbsp;-&nbsp;{{candidate.salaryNote}}
        {{/if}}
      </li>
    {{/if}}
    {{#if candidate.email}}
      <li>
        Email:&nbsp;{{candidate.email}}
        {{#if candidate.phone}}
          &nbsp;|&nbsp;Phone:&nbsp;
          {{candidate.phone}}
        {{/if}}
      </li>
    {{/if}}
    {{#if INCLUDE_CALENDAR_BOOKING}}
      <li>
        <b>Availability calendar link</b>:&nbsp;
        <a href={{candidate.calendarBookingLink}}>
          {{candidate.calendarBookingLink}}
        </a>
      </li>
    {{/if}}
    {{#if INCLUDE_REASONS_LEAVE}}
      <li>
        <b>${CANDIDATE_UI.reasonsToLeaveLastJob.summaryTitle}</b>:&nbsp;
        {{candidate.reasonsToLeaveLastJob}}
      </li>
    {{/if}}
    {{#if INCLUDE_EXPLAIN_RED_FLAGS}}
      <li>
        <b>${CANDIDATE_UI.explainRedFlags.summaryTitle}</b>:&nbsp;
        {{candidate.explainRedFlags}}
      </li>
    {{/if}}
    {{#if INCLUDE_COMPANY_INTERVIEWS}}
      <li>
        Other companies interviewing:&nbsp;
        {{candidate.otherCompanyInterviews}}
      </li>
    {{/if}}
  </ul>
`);

export const TEMPLATE__CANDIDATE_SUBMISSION__WORK_EXPERIENCE = trim(`
  {{#if LIST}}
    <ul>
      <li>
        <span>
          <b>Company</b> experience highlights
        </span>
        {{#if DISCLAIMER}}
          &nbsp;-&nbsp;
          <span style="color:#767779;font-size:0.75rem;">
            Disclaimer: Link and data accuracy not guaranteed.
          </span>
        {{/if}}
      </li>
      <ul style="list-style-type:disc;">
        {{#each LIST}}
          <li><b>{{{this.EMPLOYER_ORG_NAME}}}</b>: {{{this.SIGNALS}}}.{{{CRUNCHBASE}}}{{{LINKEDIN}}}</li>
        {{/each}}
      </ul>
    </ul>
  {{/if}}
`);

export const TEMPLATE__CANDIDATE_SUBMISSION__JOB_NOTES__READ_ONLY = trim(`
  {{#if MISSED_QUESTIONS}}
    <p>
      <strong>ALERT!! => Following questions were missed</strong>
    </p>
    <ul>{{{MISSED_QUESTIONS}}}</ul>
    {{/if}}
  {{#if BREAK_LINE}}
    <p><br/></p>
  {{/if}}
  {{#if ASKED_BY_10X10}}
    <p>
      <strong>Asked by 10x10</strong>
    </p>
    <ul>{{{ASKED_BY_10X10}}}</ul>
  {{/if}}
`);

export const TEMPLATE__CANDIDATE_SUBMISSION__JOB_NOTES__EDITABLE = trim(`
  {{#if ASKED_BY_OTHER}}<ul>{{{ASKED_BY_OTHER}}}</ul>{{/if}}
`);
