import { Button } from '@mui/material';
import moment from 'moment';
import { Arr } from '../../../lib/Array.lib';
import { deleteResumeFile } from '../../../lib/Candidate';
import Core from '../../../lib/Core';
import SovrenData, { RESUME_TYPE__CV, RESUME_TYPE__LINKEDIN_PDF } from '../../../lib/SovrenData';
import { sanitizeString } from '../../../lib/String.lib';
import { hideAlert, showAlert } from '../../Dialogs/AlertDialog';
import { showConfirm } from '../../Dialogs/AppConfirmationDialog';
import DragAndDropZone from '../../DragAndDrop/DragAndDropZone';
import Col from '../../Layout/Wrappers/Col';
import Fieldset from '../../Layout/Wrappers/Fieldset';
import Row from '../../Layout/Wrappers/Row';
import { LoadingImage } from '../../Shared/Loader';
import ResumeLinks from './ResumeLinks';
import Box from '../../Layout/Wrappers/Box';

export function CandidateEditResumeSection(props) {
  let { CandidateEditController = {} } = props;
  let { state } = CandidateEditController;
  let { resumeType = RESUME_TYPE__CV } = state;
  const _afterUploadResume = () => {
    hideAlert();
    showConfirm({
      title: 'Overwrite candidate data from the newly uploaded file?',
      content: (
        <Box column w100>
          <div>
            “Upload & Parse” - parse the resume and overwrite First name, Last name, Email, Phone, URLs, employment, and education. Please re-verify the accuracy since previously entered data will be overwritten.
          </div>
          <div className='mt-2'>
            “Upload File only” - use this option to upload cover letter or if you don't want parser to overwrite the data you already entered"
          </div>
        </Box>
      ),
      onAccept: () => _runSovrenParser(),
      onAcceptLabel: 'Upload & Parse',
      onCancelLabel: 'Upload File only'
    });
  }
  const _onClickReparse = ({ url, filename }) => {
    hideAlert();
    showConfirm({
      title: 'Overwrite candidate data by re-parsing this file?',
      message: (
        <>
          Parse resume again and overwrite First name, Last name, Email, Phone, URLs, employment, and education fields from the resume
        </>
      ),
      onAccept: () => {
        console.debug({ url, filename });
        CandidateEditController.setState({
          employmentHistories: [],
          educationHistories: [],
        }, state => {
          _runSovrenParser({ url, filename });
        });
      },
      onAcceptLabel: 'Reparse & overwrite'
    });
  }
  const _callbackParsedSovren = async (parsedResume, rawSovren) => {
    console.debug({ parsedResume, rawSovren });
    if (!parsedResume) { return showAlert({ message: 'Existing Parsed resume not found, contact support.', severity: 'error' }); }
    const _parsedResume = { ...parsedResume };
    delete _parsedResume.yearsOfExperience;
    delete CandidateEditController.__mappedSignals;
    CandidateEditController.setStateStore(
      (state) => {
        state.rawSovren = rawSovren;
        Object.keys(_parsedResume).forEach((key) => {

          /** 
           * @todo [2023-04-21][MS: old legacy code, deprecated, to cleanup after a beta period ]
           * Avoiding change URL format from parsed record
           * story_8892
           */
          /** * /
          if (key === 'gitHubURL' || key === 'linkedInURL') {
            const regxGit = new RegExp('github.com/\\w+', 'i');
            const regxGitDirty = new RegExp('github.com/\\w+.+?(?=linkedin)', 'i');
            const regxlinked = new RegExp('linkedin.com/in/\\w+', 'i');
            const regxlinkedDirty = new RegExp('linkedin.com/in/\\w+.+?(?=github)', 'i');
            let links = _parsedResume[key] || '';
            let githubUrl = Array.isArray(links.match(regxGit)) ? links.match(regxGit)[0] : '';
            let linkedInUrl = Array.isArray(links.match(regxlinked)) ? links.match(regxlinked)[0] : '';
            if (/linked/i.test(githubUrl)) {
              githubUrl = Array.isArray(githubUrl.match(regxGitDirty)) ? githubUrl.match(regxGitDirty)[0] : '';
            }
            if (/github/i.test(linkedInUrl)) {
              linkedInUrl = Array.isArray(linkedInUrl.match(regxlinkedDirty))
                ? linkedInUrl.match(regxlinkedDirty)[0]
                : '';
            }
            state[key] = state[key] || githubUrl;
            state['linkedInURL'] = state['linkedInURL'] || linkedInUrl;
          }
          /** */

          if (['employmentHistories', 'educationHistories'].includes(key)) {
            state[key] = _parsedResume[key];
          }
          else {
            state[key] = state[key] || sanitizeString(_parsedResume[key], key === 'phone' ? key : '');
          }
        });
        state.parsedResumeModel = _parsedResume;
        state.showParserPopup = true;
        return state;
      }
    );
    CandidateEditController.setState({ sovrenPushedToMongo: true });
    hideAlert();
  };
  const _runSovrenParser = async ({ url, filename, file, revisionDate = moment().toISOString() } = {}) => {
    const { currentUploadedResume, currentResumeTimestamp } = state;
    showAlert({ message: 'Parser in progress...' });
    await SovrenData.parseResume(
      file
        ? {
          resume: file,
          revisionDate,
          resumeType
        }
        : currentUploadedResume
          ? {
            resume: currentUploadedResume,
            revisionDate: currentResumeTimestamp,
            resumeType
          }
          : {
            resumeUrl: url,
            revisionDate,
            resumeType
          }
    ).then(({ parsedResume, rawSovren }) => {
      if (resumeType === RESUME_TYPE__CV) {
        let { pdfResume, htmlResume, textResume } = rawSovren;
        CandidateEditController.setStateStore(
          (state) => {
            state.sovrenDataId = rawSovren.id || rawSovren._id;
            state.resumePdfUrl = pdfResume;
            state.resumeTxtUrl = textResume;
            state.htmlResumeUrl = htmlResume;
            state.resumeScrubbedUrl = '';
            state.resumeJsonUrl = '';
            state.anonFilename = filename;
            return state;
          }
        );
      }
      setTimeout(() => _callbackParsedSovren(parsedResume, rawSovren));
    }).catch(error => {
      Core.showError(error);
      if (resumeType === RESUME_TYPE__LINKEDIN_PDF) { return; } // case: linkedin upload resume.
      CandidateEditController.setStateStore((state) => {
        state.resumes = Arr(state.resumes).map(resume => {
          if (
            (resume.filename === state.currentResumeFilename) // case: upload resume.
            || (resume.url === url) // case: re-parse.
          ) {
            resume.parseError = error;
          }
          return resume;
        });
        return state;
      });
    });
  };
  const _switchResumeType = (resumeType) => (event) => CandidateEditController.setState({ resumeType });
  return (
    <>
      <Row className='mt-2'>
        <Fieldset
          title={
            <>
              Resume&nbsp;<b style={{ color: 'orange' }}>*</b>
            </>
          }
          nextToTitle={
            Core.isAdmin() && (
              <>
                <Button
                  variant={resumeType === RESUME_TYPE__CV ? 'contained' : 'outlined'}
                  color='secondary'
                  className='tt-unset mr-1'
                  size='small'
                  onClick={_switchResumeType(RESUME_TYPE__CV)}
                >
                  Resume
                </Button>
                <Button
                  variant={resumeType === RESUME_TYPE__LINKEDIN_PDF ? 'contained' : 'outlined'}
                  color='secondary'
                  className='tt-unset mr-1'
                  size='small'
                  onClick={_switchResumeType(RESUME_TYPE__LINKEDIN_PDF)}
                >
                  LinkedInPDF
                </Button>
              </>
            )
          }
        />
      </Row>
      <Row>
        <Col>
          <DragAndDropZone
            accept='application/pdf, application/msword, .pdf, .doc, .docx, .txt'
            placeholder={
              <>
                Drag Resume to Upload (PDF, Word, Text)
                <br />
                <button style={{ width: 120 }}>Choose file</button>
                {Core.isPath('candidate/create') && 1 === 0 && (
                  <span className='c-black-medium f-small'>
                    <br />
                    <br />
                    Parser will extract resume's info to pre-fill some fields
                    <br />
                    (beta version)
                  </span>
                )}
              </>
            }
            value={CandidateEditController.state.resumes}
            maxSize={4000000}
            onChange={(ev, resume, err) => {
              const extension = String(resume.name).split('.').pop();
              if (err) {
                if (Object(resume).size > 4000000) {
                  Core.showError('File size exceeded');
                } else {
                  Core.showError('File rejected due to unknown error, please contact support');
                }
              }
              if (!/pdf|doc|docx|txt/i.test(extension)) {
                Core.showError('File rejected as only pdf, doc, docx and txt are supported');
              } else if (!err) {
                if (resumeType === RESUME_TYPE__LINKEDIN_PDF) {
                  return _runSovrenParser({ file: resume });
                }
                showAlert({ message: 'File upload is in progress...', icon: LoadingImage });
                let filename = `${String(CandidateEditController.state.firstName).replace(/ /g, '')
                  .toUpperCase()}_${String(CandidateEditController.state.lastName).replace(/ /g, '')
                    .toUpperCase()}_${moment().format('YYYYMMDD_HHmmss')}.${extension}`;
                Core.uploadFile(
                  CandidateEditController.state.id,
                  resume,
                  filename,
                  (url) => {
                    let currentResumeTimestamp = new Date().getTime();
                    CandidateEditController.setStateStore(
                      {
                        resumes: [
                          {
                            url,
                            createdAt: new Date().toISOString(),
                            timestamp: currentResumeTimestamp,
                            filename
                          },
                          ...CandidateEditController.state.resumes,
                        ],
                        key: 'resumes',
                        currentResumeTimestamp,
                        currentUploadedResume: resume,
                        currentResumeFilename: filename
                      },
                      () => {
                        if (CandidateEditController.state.id) {
                          _afterUploadResume();
                        }
                        else {
                          if (
                            CandidateEditController.state.resumes.length > 1 ||
                            CandidateEditController.state.dirtyAttrs.filter((attr) => !!attr && attr !== 'resumes').length
                          ) {
                            _afterUploadResume();
                          } else {
                            _runSovrenParser({ url, filename });
                          }
                        }
                      }
                    );
                  },
                  Core.showError
                );
              }
            }}
            fullWidth
          />
        </Col>
        <Col>
          {!!CandidateEditController.state.resumes.length && (
            <ResumeLinks
              resumes={CandidateEditController.state.resumes}
              hasNewEntry={state.sovrenPushedToMongo}
              onError={Core.showError}
              onDelete={(ev, resume, index) => {
                showConfirm({
                  title: 'Do you want to delete?',
                  message: <>{decodeURIComponent(String(resume.url).split('/').pop())}</>,
                  async onAccept() {
                    CandidateEditController.setStateStore({
                      resumes: CandidateEditController.state.resumes.filter((r, i) => i !== index),
                      key: 'resumes',
                    });
                    if (CandidateEditController.state.id) {
                      setTimeout((st) => {
                        CandidateEditController.save({ resumes: CandidateEditController.state.resumes });
                      });
                    }
                    let candidate = CandidateEditController.state;
                    await deleteResumeFile({ resume });
                    SovrenData.destroyData({
                      resume, candidate, callback: CandidateEditController.afterDestroySovrenData
                    });
                  },
                  onAcceptLabel: 'Delete'
                });
              }}
              onReparse={_onClickReparse}
            />
          )}
        </Col>
      </Row>
    </>
  );
}
