import {
  delay
} from 'lodash';
import React, {
  useRef
} from 'react';
import ReactQuill, {
  Quill
} from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import AppUI from '../../../dictionaries/AppUI.dic';
import {
  Fun
} from '../../../lib/Function.lib';
import useState from '../../../lib/hooks/useState.hook';
import {
  joinClassName,
  mapWrapper
} from '../Libraries/Theme.lib';
import Box from './Box';

// Register a new Block (optional if using a different block element)
const Block = Quill.import('blots/block');
Block.tagName = 'div';
Quill.register(Block);

/**
 * RichTextBox component for handling rich text
 * input and editing.
 *
 * The RTB receives a value and an onChange callback
 * to update the parent value. The value is
 * auto-controlled by this component. Changes coming
 * from the parent will be merged after a 100ms delay
 * to prevent echo issues.
 *
 * The default look is a read-only view. When clicking
 * on the read-only view, the editor view (react-quill)
 * will appear. If RTB is disabled, the read-only view
 * will appear, and the toggle button will be hidden.
 *
 * The read-only view calls `fixQuillHtml` (method)
 * to parse react-quill list classes to pure HTML.
 * @look for `sendSafeEmail`, `createCalendarEventAndNotify`,
 * `createCalendarEventOnly` (methods), they also use
 * `fixQuillHtml`.
 *
 * @see https://www.npmjs.com/package/react-quill
 * @see https://github.com/zenoamaro/react-quill
 * 
 * @todo Look for upgrades...
 * @see https://github.com/zenoamaro/react-quill/issues/989
 * @see https://www.npmjs.com/package/react-quill-new
 * @see https://github.com/VaguelySerious/react-quill
 *
 * @param {Object} params - Parameters for the RichTextBox component.
 * @param {boolean} [params.acl=true] - Access control flag, determines if the component should render.
 * @param {boolean} [params.debug=false] - Debug flag for logging additional information.
 * @param {string} [params.placeholder=''] - Placeholder text for the editor.
 * @param {string} [params.value=''] - Initial value of the editor content.
 * @param {string} [params.autoComplete=''] - Autocomplete behavior.
 * @param {boolean} [params.autoFocus=false] - Determines if the editor should autofocus.
 * @param {Function} [params.onChange=Fun] - Callback for when the content changes.
 * @param {Function} [params.onBlur=null] - Callback for when the editor loses focus.
 * @param {Function} [params.onFocus=null] - Callback for when the editor gains focus.
 * @param {Function} [params.onKeyDown=null] - Callback for handling keydown events.
 * @param {Function} [params.onKeyPress=null] - Callback for handling keypress events.
 * @param {string} [params.className=''] - Additional class names for the wrapper.
 * @param {Object} [params.style={}] - Inline styles for the wrapper.
 * @param {boolean} [params.disabled=false] - Flag to disable the editor.
 * @param {boolean} [params.taller=false] - Flag to make the editor taller.
 * @param {boolean} [params.xtaller=false] - Flag to make the editor extra tall.
 * @param {boolean} [params.minH128=false] - Flag to set a minimum height of 128px.
 * @param {Object} [params.wrapperProps={}] - Additional props for the wrapper element.
 * @param {Object} [params.rtbProps={}] - Additional props for the rich text editor component.
 * @param {...Object} props - Additional properties passed to the component.
 *
 * @returns {JSX.Element|null} - The RichTextBox component or null if acl is false.
 */
export default function RichTextBox({
  acl = true,
  debug = false,
  placeholder = '',
  value: propsValue = '',
  autoComplete = '',
  autoFocus = false,
  onChange = Fun,
  onBlur = null,
  onFocus = null,
  className = '',
  style = {},
  disabled = false,
  taller = false,
  xtaller = false,
  minH128 = false,
  wrapperProps = {},
  rtbProps = {},
  exportOnBlur = undefined,
  anchorEl = undefined,
  ...props
}) {
  const focused = useRef(false);
  const localChangeTime = useRef(0);
  const input = useRef(null);
  const prevPropsValue = useRef(propsValue);
  const [state, updateState] = useState({ value: propsValue });

  // This effect is used to detect external value changes.
  React.useEffect(() => {
    const DELAY = 100;
    const NOW = Date.now();
    const YES__CHANGE_EXTERNAL = (propsValue !== prevPropsValue.current);
    const YES__CHANGE_LOCAL = (propsValue !== state.value);
    const YES__SHOULD_UPDATE_INSTANTLY = (
      (
        NOW - Number(localChangeTime.current)
      ) > DELAY
    );
    if (
      YES__CHANGE_EXTERNAL &&
      YES__CHANGE_LOCAL
    ) {
      if (YES__SHOULD_UPDATE_INSTANTLY) {
        prevPropsValue.current = propsValue;
        updateState({ value: propsValue });
      }
      else {
        // Wait 100ms before re-trigger this effect
        // to prevent omissions.
        delay(updateState, DELAY);
      }
    }
  }, [propsValue, state.value]);

  if (!acl) { return null; }

  // NOT flags
  const NOT__EDITING = !state.editing;

  // ON CHANGE HANDLER
  const _onChange = async (value) => {
    console.debug('CHANGE', value);
    localChangeTime.current = Date.now();
    await updateState({ value });
    onChange(value);
  };

  /**
   * ON BLUR HANDLER
   * 
   * Handles the Rich Text Editor losing focus.
   * - Checks if the blurred element belongs to the editor (`ql-editor` class).
   * - If true:
   *   - Sets `focused` to false.
   *   - Waits 100ms using the `delay` function.
   *   - If `focused` is still false after the delay:
   *     - Sets `editing` to false in the component's state.
   *     - Calls `onBlur` with the current editor value.
   * 
   * The delay prevents the editor from losing focus immediately
   * when interacting with elements inside it (e.g., color menu).
   */
  const _onBlur = async (event) => {
    console.debug('BLUR', event, state.value);
    if (event.target?.classList.contains('ql-editor')) {
      focused.current = false;
      delay(async () => {
        if (focused.current === false) {
          await updateState({ editing: false });
          Fun(onBlur)(state.value);
        }
      }, 100);
    }
  };

  /**
   * ON FOCUS HANDLER
   *
   * Handles when the Rich Text Editor gains focus.
   * - Sets `focused` to true.
   * - Waits 100ms using a delay function.
   * - If `focused` is still true after the delay:
   *   - Updates the component's state to `editing: true`.
   *   - Calls the `onFocus` callback function.
   * 
   * The delay prevents unintended state changes when quickly
   * switching focus within the editor.
   */
  const _onFocus = async (event) => {
    console.debug('FOCUS', event);
    focused.current = true;
    delay(async () => {
      if (focused.current === true) {
        await updateState({ editing: true });
        Fun(onFocus)(event);
      }
    }, 100);
  };

  // ATTRIBUTES
  placeholder = placeholder || (disabled ? AppUI.disabled : AppUI.placeholder.multiline);
  className = joinClassName([
    'flex-column flex-1',
    className
  ]);

  // CONFIGURATIONS
  const flags = { debug, disabled };
  const colors = [
    '#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc',
    '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc',
    '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66',
    '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00',
    '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000',
    '#663d00', '#666600', '#003700', '#002966', '#3d1466', '#536dfe',
    '#009688', '#4f8078'
  ];
  const toolbar = [
    [{ header: '1' }, { header: '2' }],
    [{ color: colors }, { background: colors }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link'],
    ['clean']
  ];
  const clipboard = { matchVisual: false };
  const modules = { toolbar, clipboard };
  const formats = [
    'header',
    'font',
    'size',
    'color',
    'background',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'indent',
    'link',
    'image',
    'video'
  ];

  return (
    <Box column
      {...mapWrapper({
        role: 'RichTextBox__Wrapper__Editing',
        props: wrapperProps,
        assign: {
          onBlur: _onBlur,
          onFocus: _onFocus,
          className,
          style,
        }
      })}
    >
      <ReactQuill
        {...mapWrapper(
          {
            role: 'RichTextBox',
            props: { ...rtbProps },
            assign: {
              ...props,
              value: state.value,
              onChange: _onChange,
              theme: 'snow',
              placeholder,
              modules,
              formats,
              readOnly: disabled,
              className: joinClassName([
                'flex-column flex-1',
                disabled && 'ql-rtb-disabled',
                xtaller ? 'ql-xtaller' : taller ? 'ql-taller' : 'ql-normal',
                minH128 && 'ql-min-h128',
                NOT__EDITING && 'ql-not-editing',
              ])
            },
            flags
          }
        )}
        ref={input}
      />
    </Box>
  );
}