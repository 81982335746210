import _, {
  debounce
} from "lodash";
import dig from "object-dig";
import queryString from "query-string";
import {
  Component
} from "react";
import {
  withTranslation
} from "react-i18next";
import {
  Arr,
  join,
  sanitizeArr
} from "../../lib/Array.lib";
import {
  NOT,
  YES
} from '../../lib/Boolean.lib';
import Candidate from "../../lib/Candidate";
import Core from "../../lib/Core";
import {
  ACCOUNT_ACTION__EDIT_CANDIDATE,
  ACCOUNT_ACTION__EDIT_JOB,
  STATE_ACTIVE
} from "../../lib/Definition";
import Engagement from "../../lib/Engagement";
import {
  Fun
} from '../../lib/Function.lib';
import Job from "../../lib/Job";
import {
  Obj
} from '../../lib/Object.lib';
import SourceListHash from "../../lib/SourceListHash";
import Store from "../../lib/Store";
import {
  Str
} from "../../lib/String.lib";
import copyHtml from "../../lib/tools/copyHtml";
import getJobSourceListApis from "../../lib/tools/getJobSourceListApis";
import onReady from '../../lib/tools/onReady';
import {
  getLocation,
  getParams,
  getSearch
} from '../../lib/URL.lib';
import EditJobSourceList from "../Accounts/Admin/EditJobSourceList";
import ConfirmDialog from "../Dialogs/ConfirmDialog";
import FilterControl from "../FilterControl/FilterControl";
import {
  THEME__COLOR__SECONDARY
} from '../Layout/Libraries/Theme.lib';
import Autocomplete from '../Layout/Wrappers/Autocomplete';
import Box from '../Layout/Wrappers/Box';
import Button from '../Layout/Wrappers/Button';
import Checkbox from '../Layout/Wrappers/Checkbox';
import Dialog from '../Layout/Wrappers/Dialog';
import FloatingActionButton from '../Layout/Wrappers/FloatingActionButton';
import Menu from '../Layout/Wrappers/Menu';
import Navigate from '../Layout/Wrappers/Navigate';
import NavLink from '../Layout/Wrappers/NavLink';
import Snackbar from '../Layout/Wrappers/Snackbar';
import { Label } from '../Layout/Wrappers/Typography';
import List from "../List/ListNoPaging";
import JobCard from "./Card/JobCard";
import JobSourceList from "./JobSourceList.lib";

class Jobs extends Component {
  data;
  constructor() {
    super(...arguments);
    this.name = "Jobs";
    const _jobId = getParams({ pattern: '/job/view/:id' }).id;
    this.state = {
      showAll: false,
      snackBarMessage: "",
      snackBarOpen: false,
      selected: false,
      isSubmittingCandidate: false,
      isSubmittingCandidateChoice: false,
      candidates: [],
      submittingCandidatePicked: null,
      params: queryString.parse(getSearch()),
      displayEngagements: false,
      hasEngagementsFetched: false,
      allJobs: [],
      recruiterJobIds: [],
      collectedSourceLists: [],
      jobIdHashedWithAccount: {},
      loadingWithEngagements: !!_jobId
    };
    Store.set("path", getLocation());
    this.reloadData = (ev) => this.loadData(true);
  }

  async componentDidMount() {
    await onReady(this, 'filterControl');
    this.filterControl.setItems([], /^active/i);
    setTimeout(() => {
      this.loadData();
      this.getRecruiterJob();
    });
  }

  getRecruiterJob = () => {
    if (Core.isRecruiter()) {
      getJobSourceListApis.getJobIdsByRecruiter((recruiterJobIds) =>
        this.setState({
          recruiterJobIds: recruiterJobIds.map((job) => ({
            jobId: job.jobId,
            startDate: job.startDate,
          })),
        })
      );
    }
  };

  setJobIdsHash = () => {
    let hash = {};
    hash = SourceListHash(this.state.collectedSourceLists, "jobId");

    this.setState({ jobIdHashedWithAccount: hash }, () => {
      this.List.setItems(this.state.filteredItems);
    });
  };

  getRecruiterDetailsThroughJobs = debounce(async (filtered) => {
    const { allJobs, jobIdHashedWithAccount } = this.state;
    const onlyIds = Arr(filtered ? filtered : allJobs)
      .map((job) => Obj(job).id)
      .filter(id => !!id && !jobIdHashedWithAccount[id]);
    if (Core.isAdmin()) {
      let chunked = _.chunk(onlyIds, 200);
      const getRecruiterDetailByJobIds = async (subArray) => new Promise((resolve) => {
        getJobSourceListApis.getRecruiterDetailByJobIds(
          "jobId",
          subArray,
          (response) => {
            /**
             * "Job source list shows both active and inactive sourcing recruiters.
             * Should only show active (only has a start date)."
             * @see story 2534 task 10
             */
            const filteredActiveSource = [...response].filter(n => !n.endDate);
            resolve(filteredActiveSource);
          }
        );
      });
      for (const subArray of chunked) {
        const filteredActiveSource = await getRecruiterDetailByJobIds(subArray);
        this.setState(
          {
            collectedSourceLists: [
              ...this.state.collectedSourceLists,
              ...filteredActiveSource,
            ],
          },
          () => this.setJobIdsHash()
        );
      }
    }
  });

  // story_9696
  // New local flag and method to detect if "Active" chip was changed in the FilterControl.
  filterActiveFlag = true;
  isFilterActiveSet() {
    return YES(
      this.filterControl.state.chips.find(
        ({ name }) => !!Str(name).match(/^active/i)
      )
    );
  }

  loadData = async (force = false) => {
    setTimeout(async () => {
      await onReady(this, 'filterControl');
      const { displayEngagements, hasEngagementsFetched } = this.state;
      // story_9696
      // NEW parameter to force load data when it is required to reload data.
      if (!force && !!hasEngagementsFetched) {
        this.List.setItems(this.state.filteredItems);
        return;
      }

      let opts = { commonQuery: { engagements: displayEngagements } };
      let allJobs = [];

      const { jobId } = getParams({ pattern: '/job/view/:jobId' });
      if (jobId) {
        const where = { id: jobId };
        Job.getWhere(
          where,
          (jobs) => {
            allJobs = jobs
              .filter((job) => job.id === jobId)
              .map((job) => {
                job.expanded = true;
                return job;
              });
            this.List.setItems(allJobs);
            this.setState({
              allJobs,
              hasEngagementsFetched: displayEngagements,
            });
          },
          opts
        );
      }
      /** */
      else if (Core.isAdminOrCoordinator()) {
        Job.cleanCache();
        this.setState({ loadingWithEngagements: true });
        // story_9696
        // SET "STATE_ACTIVE" in query filter if chip is set in the FilterControl (default true).
        let filter = {};
        let selected;
        if (this.isFilterActiveSet()) {
          filter = {
            where: { state: STATE_ACTIVE }
          };
          selected = /^active/i;
        }
        Job.getAll(
          (jobs) => {
            this.filterControl.setItems(jobs, selected);
            this.setState(
              {
                allJobs: jobs.filter((job) => job.state === 1),
                loadingWithEngagements: false,
                hasEngagementsFetched: displayEngagements,
              },
              () => {
                this.getRecruiterDetailsThroughJobs();
              }
            );
          },
          filter,
          opts
        );
      }
      /** */
      else {
        Job.getActives(
          (jobs) => {
            this.filterControl.setItems(jobs, /^active/i);
            this.setState(
              { allJobs: jobs, hasEngagementsFetched: displayEngagements },
              () => {
                this.getRecruiterDetailsThroughJobs();
              }
            );
          },
          opts
        );
      }
    });

  };

  showMessage = (msg) => {
    this.setState({
      snackBarMessage: msg,
      snackBarOpen: true,
    });
  };

  hideMessage = () => {
    this.setState({
      snackBarMessage: "",
      snackBarOpen: false,
    });
  };

  bulkCopy(type) {
    const t = this.props.t;
    if (!this.state.selected || this.state.selected.length === 0) {
      Core.showWarning("Please select at least 1 job to copy. The selected jobs’ description will be copied to paste elsewhere");
      return;
    }
    let body = "";
    Core.log({ sel: this.List.state.selected });
    this.List.selected.forEach((state) => {
      const card = this.List.cards[state.id];
      if (card && card.getPreview) {
        body += card.getPreview(type);
        body += "<br/><hr/>";
      }
    });
    Core.log({ length: body.length });
    copyHtml(`
      ${body}
    `)
      .then((em) => {
        Core.log("Copy email command was successful");
        Core.showSuccess(
          t('jobs.copyButton.successful')
        );
      })
      .catch((ex) => {
        Core.log("Oops, unable to copy");
        Core.showError("Fail copy!");
      });
  };

  dialogClose = (state) => () => {
    this.setState(state);
  };

  submittingCandidateEdit = () => {
    this.closeAndResetCandChoice();
    this.setState({ isSubmittingCandidate: true });
  };

  //may be used when clicked in card and need to show data in expanded
  fetchEngagements = (cb) => {
    const { selected } = this.state;
    let jobEngagements = [];
    let count = 0;
    selected.forEach((job) => {
      Engagement.getWhere({ jobId: job.id }, (resp) => {
        jobEngagements.push(resp);
        count++;
        if (count === selected.length) {
          !!cb && cb(jobEngagements);
        }
      }, {}, { source: `jobs__fetch_engagements` });
    });
  };

  recruiterJobs = () => {
    const { recruiterJobIds } = this.state;
    const recruiterOnlyIds = recruiterJobIds.map((job) => job.jobId);
    const { allJobs } = this.state;
    let filteredJobItems = [],
      restoredAddedJobsWithDates = [];

    if (!!allJobs.length) {
      filteredJobItems = allJobs.map((d) => ({ id: d.id, label: d._name }));

      return (
        <EditJobSourceList
          accountJobSourceList={filteredJobItems}
          title='Edit my sourcing'
          values={recruiterOnlyIds}
          addButtonClass={"list-add-new chip-button m-0 mb-1 mr-1"}
          labelColor={"#ffffff"}
          heading="Add or delete job to source"
          onChange={(jobs, jobObj) => {
            JobSourceList.process(recruiterOnlyIds, jobs);
            JobSourceList.sendEmailManager(
              recruiterOnlyIds,
              jobs,
              filteredJobItems,
              recruiterJobIds
            );
            restoredAddedJobsWithDates = JobSourceList.restoreAddedJobsWithDates(
              recruiterJobIds,
              jobs
            );
            this.setState(
              { recruiterJobIds: restoredAddedJobsWithDates },
              () => {
                this.List.setItems(this.state.filteredItems);
              }
            );
          }}
        />
      );
    }

    return null;
  };

  dialogCreateCandChoice = () => {

    const isShowingCandDropdown = (
      this.state.submittingCandidateChoice === "ex" &&
      this.state.candidates.length >= 0
    );

    const isCandSelected = !!this.state.submittingCandidatePicked;

    const _setSelected = (submittingCandidatePicked) => {
      this.setState({
        submittingCandidatePicked
      });
    };

    const _renderLabel = ({ _name, email, phone }) => join(
      [_name, email, phone],
      ' | '
    )

    const _fetchOptions = async (search = '') => {
      // @note  Alway must be something to search, default: "a".
      const iLikeStruct = {
        like: `.*${search || 'a'}.*`,
        options: "i"
      };
      const query = {
        accountId: Core.getUserId(),
        or: [
          { firstName: iLikeStruct },
          { lastName: iLikeStruct },
          { email: iLikeStruct },
          { phone: iLikeStruct },
          { linkedInURL: iLikeStruct },
          { gitHubURL: iLikeStruct },
        ],
      };
      if (Core.isAdmin()) {
        delete query.accountId;
      }
      return Candidate.getWhere(
        query,
        null,
        {
          limit: 50,
          fields: [
            'id',
            'accountId',
            'firstName',
            'lastName',
            'email',
            'phone',
          ]
        }
      ).then(
        /** 
         * @note
         * We must map the model to avoid cycled loops.
         */
        (candidates = []) => candidates.map(
          (
            { id, _name, email, phone }
          ) => (
            { id, _name, email, phone }
          )
        )
      );
    }

    return (
      <Dialog keepMounted
        open={this.state.isSubmittingCandidateChoice}
        onClose={this.handleClose}
        title={isShowingCandDropdown && 'Enter Existing Candidate Name or Email'}
        content={
          <>

            <Box column alignCenter
              acl={!isShowingCandDropdown}
            >
              <Button w100
                label={`Create New Candidate?`}
                onClick={this.handlerSubmitCandidateOpt("new")}
              />
              <Label w100 alignCenter className='my-05'>
                OR
              </Label>
              <Button w100
                label={`Pick from Existing Ones?`}
                onClick={this.handlerSubmitCandidateOpt("ex")}
              />
            </Box>

            <Autocomplete autoFocus acl={isShowingCandDropdown}
              value={this.state.submittingCandidatePicked}
              onSelect={_setSelected}
              renderLabel={_renderLabel}
              asyncOptions={_fetchOptions}
            />

          </>
        }
        actions={
          sanitizeArr([
            <Button outlined minW120
              label={`Cancel`}
              onClick={this.closeAndResetCandChoice}
            />,
            isShowingCandDropdown && isCandSelected && (
              <Button primary minW120
                label={`SUBMIT CANDIDATE`}
                onClick={this.submittingCandidateEdit}
              />
            )
          ])
        }
        paperStyle={{ width: 640 }}
      />
    );
  };

  closeAndResetCandChoice = () => {
    this.dialogClose({
      isSubmittingCandidateChoice: false,
      submittingCandidateChoice: null,
      isSubmittingCandidate: false,
    })();
  };

  dialogSubmitCandidate = () => {
    if (!this.state.isSubmittingCandidate) {
      return;
    }
    let defaultValues = {};
    let permittedJobs = this.state.selected
      ? this.state.selected.map((o) => o.id)
      : [];
    let roles = this.state.selected
      ? this.state.selected.map((o) => o.role)
      : [];

    if (permittedJobs.length) {
      defaultValues["jobsPermitted"] = permittedJobs;
    }

    if (roles.length) {
      let set = new Set(roles);
      defaultValues["roles"] = Array.from(set);
    }

    let candidateId = dig(this.state, "submittingCandidatePicked");
    let path = "";
    let queryString =
      "source=jobs&defaultRoles=" +
      roles.join(",") +
      "&" +
      "defaultJobs=" +
      permittedJobs.join(",");

    if (candidateId) {
      path = `/candidate/edit/${candidateId}?${queryString}`;
    } else {
      path = `/candidate/create?${queryString}`;
    }

    return <Navigate to={path} />;
  };

  handlerSubmitCandidate = () => {
    if (this.state.selected.length > 0) {
      this.setState({ isSubmittingCandidateChoice: true });
    } else {
      this.setState({ isSubmittingCandidate: true });
    }
  };

  handlerSubmitCandidateOpt = (val) => () => {
    this.setState({
      submittingCandidateChoice: val,
      isSubmittingCandidate: val === "new",
    });
  };

  handleClickBulkCopy = (event) => {
    this.setState({ bulkCopyAnchorEl: event.currentTarget });
  };

  handleCloseBulkCopy = (type) => (ev) => {
    this.setState({ bulkCopyAnchorEl: null });
    !!type && this.bulkCopy(type);
  };

  render() {
    Core.setKeyValue('JobsController', this);

    if (Core.isLoggedOut()) {
      return <Navigate to="/login" />;
    }

    const { t } = this.props;
    const {
      recruiterJobIds,
      jobIdHashedWithAccount,
      displayEngagements,
    } = this.state;
    const {
      List: ListController = {}
    } = this;
    const {
      selected: selectedJobs = []
    } = ListController;
    const isCopyJobsDisabled = !selectedJobs.length;
    const _jobId = getParams({ pattern: '/job/view/:id' }).id;
    return (
      <Box column h100 w100>

        <FilterControl
          Controller={this}
          menus={Job.menus}
          more={Job.more}
          source={"jobs"}
          onChange={(filtered) => {
            this.setState({ filteredItems: filtered }, () => {
              this.getRecruiterDetailsThroughJobs(filtered);
            });
            Fun(Obj(this.List).setItems)(filtered);
            setTimeout(() => {
              // story_9696
              // IF the "Active" filter chip change, force refetch list.
              const currentFilterActiveFlag = this.isFilterActiveSet();
              if (this.filterActiveFlag !== currentFilterActiveFlag) {
                this.filterActiveFlag = currentFilterActiveFlag;
                this.reloadData();
              }
            });
          }}
          toolBarRight={
            <Box row flex1 alignRight>

              <Checkbox acl={Core.isAdmin()}
                label={
                  <span className='f-sm c-black-medium'>
                    {
                      this.state.loadingWithEngagements
                        ? "Engagements Stats - loading..."
                        : "Engagements Stats"
                    }
                  </span>
                }
                className="pl-2 mr-2"
                checked={this.state.displayEngagements}
                onCheck={(ev, displayEngagements) => {
                  this.setState({ displayEngagements }, () => {
                    this.loadData();
                  });
                }}
                size='small'
                style={{ maxHeight: 32 }}
                inputProps={{ className: 'icon24 mr-1' }}
              />

              {Core.isRecruiter() && this.recruiterJobs()}

              <Button small acl={NOT(Core.isAdmin()) || Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_CANDIDATE })}
                label={
                  !!this.state.selected.length
                    ? `Submit Candidate to ( ${this.state.selected.length} ) Jobs`
                    : "Submit new Candidate"
                }
                onClick={this.handlerSubmitCandidate}
                className="w-auto mr-1"
              />

              <Box role='CopyJobsMenu' wAuto>
                <Button primary small
                  disabled={isCopyJobsDisabled}
                  className='mr-1'
                  onClick={this.handleClickBulkCopy}
                >
                  <Box title={t('jobs.copyButton.ariaLabel.label')}>
                    Copy Job(s)
                  </Box>
                </Button>
                <Menu
                  name='jobs__bulk_copy'
                  anchorEl={this.state.bulkCopyAnchorEl}
                  open={!!(this.state.bulkCopyAnchorEl)}
                  onClose={this.handleCloseBulkCopy()}
                  options={[
                    {
                      id: 'long_jd',
                      label: 'Long JD',
                      onClick: this.handleCloseBulkCopy('large')
                    },
                    {
                      id: 'short_jd',
                      label: 'Short JD',
                      onClick: this.handleCloseBulkCopy('short')
                    }
                  ]}
                />
              </Box>

              <NavLink
                acl={Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_JOB })}
                to="/job/create"
                className="ui-m-min"
              >
                <Button small minW120
                  label="+ New Job"
                  className="c-white c-white-hover"
                />
              </NavLink>

            </Box>
          }
          className={_jobId ? 'd-none' : ''}
        />

        <Box column h100 w100 scrollY>

          <List
            ref={(self) => !!self && (this.List = self)}
            tabs={Job.listTabs}
            tab={Job.listTab}
            disableTabs={!!_jobId}
            name="Job"
            card={JobCard}
            params={this.state.params}
            parent={this}
            onCheck={(selected) => {
              this.setState({ selected });
            }}
            floatingButton={
              Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_JOB }) && (
                <NavLink className="ui-m-max" to={`/job/create`}>
                  <FloatingActionButton
                    className="list-floating-button"
                    color={THEME__COLOR__SECONDARY}
                  />
                </NavLink>
              )
            }
            extraInfo={{
              recruiterJobIds,
              jobIdHashedWithAccount,
              displayEngagements,
            }}
          />

        </Box>

        {this.dialogSubmitCandidate()}

        {this.state.selected && this.dialogCreateCandChoice()}

        <Snackbar
          open={this.state.snackBarOpen}
          message={this.state.snackBarMessage}
          className="snack-bar"
          autoHideDuration={4000}
          onClose={this.hideMessage}
        />

        <ConfirmDialog
          ref={(self) => (this.ConfirmDialog = self)}
          title="Delete Job(s)?"
          message="This action can't be undone."
          actionLabel="Delete"
        />

      </Box>
    );
  }
}

/* EXPORTS ==================================== */

export default withTranslation()(Jobs);

/* ============================================ */
