import {
  Avatar
} from '@mui/material';
import {
  Input,
  Select
} from 'antd';
import moment from 'moment';
import {
  useState
} from 'react';
import AccountLib from '../../../lib/Account';
import {
  Arr
} from '../../../lib/Array.lib';
import Company, {
  NEGATIVE_COMPANY_SIGNAL_TAG_IDS,
  POSITIVE_COMPANY_SIGNAL_TAG_IDS
} from '../../../lib/Company';
import Core from '../../../lib/Core';
import CrunchbaseLib, {
  CRUNCHBASE__FAVICON, SUGGESTION_TYPE__CBO
} from '../../../lib/Crunchbase.lib';
import Definition from '../../../lib/Definition';
import {
  evalSameName,
  mapUpdatedSet
} from '../../../lib/GenericTools.lib';
import {
  Obj
} from '../../../lib/Object.lib';
import School, {
  NEGATIVE_SCHOOL_SIGNAL_TAG_IDS,
  POSITIVE_SCHOOL_SIGNAL_TAG_IDS
} from '../../../lib/School';
import {
  Abbreviation
} from '../../../lib/String.lib';
import {
  MODEL_NAME__COMPANY
} from '../../../lib/models/company.model';
import Box from '../../Layout/Wrappers/Box';
import Button from '../../Layout/Wrappers/Button';
import Chip from '../../Layout/Wrappers/Chip';
import Col from '../../Layout/Wrappers/Col';
import Dialog from '../../Layout/Wrappers/Dialog';
import IconButton from '../../Layout/Wrappers/IconButton';
import Row from '../../Layout/Wrappers/Row';
import SuggestionList from '../../Layout/Wrappers/SuggestionList';
import {
  reRenderEditCandidate
} from '../CandidateSignalTags.lib';
import {
  CrunchbaseSuggestedOption
} from '../Forms/CrunchbaseSuggestedOption';
import {
  mapOrganizationSignals
} from '../OrganizationSignals.lib';
import {
  deleteEntity
} from './Histories.derivator';

export default function EntityEdit({
  children,
  entityId: defaultValue,
  candidateId,
  historyName,
  onSave = entity => null,
  onDelete = () => null,
  className,
  title,
  context
}) {
  const {
    labelSectionTitle,
    entityModelName,
    entityIdKey,
    getEntity,
    saveEntity,
    fixEntityName,
    getSignals
  } = context;
  const entityModelNameLowerCase = entityModelName.toLowerCase();
  const isForCompany = (entityModelName === MODEL_NAME__COMPANY);
  let [state, setState] = useState({
    entityId: defaultValue,
    defaultValue
  });
  let {
    entityId,
    entity = {},
    organization = {},
    account = {},
    open,
    fetchingEntity,
    fetchingAccount,
    fetchingOrganization,
    error,
    suggestions = [],
    fetchingSuggestions,
    search,
    selected = organization?.id,
    wipeoutOrganization = false,
    wipeoutSearch = false
  } = state;
  if (defaultValue !== state.defaultValue) {
    _updateState({ entityId: defaultValue, defaultValue })
  }
  async function _updateState(update) {
    state = { ...state, ...update };
    setState(state);
    return await new Promise(resolve => setTimeout(() => resolve(state)));
  }

  async function _save() {
    Core.showMessage('Saving...');
    return saveEntity({ entity }).then((entity) => {
      Core.showSuccess(`${entityModelName} saved!`);
      onSave(entity);
      _close();
    }).catch(Core.showError);
  }

  async function _open() {
    return _updateState({ open: true, entity: undefined });
  }
  async function _close() {
    return _updateState({
      open: false,
      error: false,
      fetchingEntity: false,
      entity: undefined,
      organization: undefined,
      account: undefined,
      selected: undefined,
      wipeoutOrganization: false,
      wipeoutSearch: false
    });
  }
  function _delete() {
    deleteEntity({
      context,
      entityId,
      candidateId
    }).then(() => {
      Core.showSuccess(`${entityModelName} deleted!`);
      onDelete();
      _close();
    });
  }
  async function _onError(error) {
    _close();
    Core.showError(error);
  }

  async function _fetchEntity() {
    await _updateState({ fetchingEntity: true });
    entity = await getEntity({ id: entityId }).catch(_onError);
    if (entity) {
      entity.signalTags = mapOrganizationSignals({
        signals: entity.signalTags || {},
        type: entityModelName
      });
      if (
        !!entity.name &&
        !!historyName &&
        !entity.alternativeNames.includes(historyName) &&
        (entity.name !== historyName)
      ) {
        entity.alternativeNames.push(historyName);
      }
      await _updateState({ entity, fetchingEntity: false });
    }
    else {
      _onError(`Entity(${entityId}) not found`);
    }
  }

  async function _fetchOrganization() {
    if (!CrunchbaseLib.isEnabled() || !entity.crunchbaseOrganizationId) { return; }
    await _updateState({ fetchingOrganization: true });
    const organization = Obj(
      await CrunchbaseLib.get({
        where: {
          id: entity.crunchbaseOrganizationId
        },
        limit: 1
      }).then(
        (organizations) => Arr(organizations)[0]
      )
    );
    const update = {};
    if (organization.id) {
      update.organization = organization;
      update.selected = organization.id;
      update.entity = await fixEntityName({ entity, organization });
    }
    update.fetchingOrganization = false;
    await _updateState(update);
  }

  async function fetchAccount() {
    entity.updatedBy && _updateState({ fetchingAccount: true }).then(state => {
      AccountLib.get(entity.updatedBy).then(account => _updateState({
        account,
        fetchingAccount: false
      })).catch(_onError);
    });
  }

  async function _fetchSuggestions({ name }) {
    await _updateState({ fetchSuggestions: true });
    suggestions = await CrunchbaseLib.getSuggestions({
      name,
      type: SUGGESTION_TYPE__CBO,
      subtype: entityModelName
    }).catch(Core.showError);
    await _updateState({
      fetchSuggestions: false,
      suggestions: suggestions || []
    });
  }

  async function _regenerateSignals() {
    await getSignals({ [entityIdKey]: entity.id, reset: true })
      .then(() => Core.showSuccess('Successfully completed'))
      .catch(Core.showError);
    await reRenderEditCandidate();
    await _fetchEntity();
  }

  // Fetching stuff
  if (open) {

    // get entity
    if (!entity.id && !fetchingEntity && !error) {
      _fetchEntity();
    }

    // get account
    if (!account.id && !fetchingAccount && !error) {
      fetchAccount();
    }

    // get organization
    if (!organization.id && !fetchingOrganization && !error && !wipeoutOrganization) {
      _fetchOrganization();
    }

    console.debug('DEBUG_EDIT_ENTITY\n', state, historyName);

  }

  let __defaultSearch = (wipeoutOrganization ? '' : search || entity.name || organization.name || historyName);

  let dialogId = `draggable-dialog-title-configure-entity`;

  return (
    <>
      {entityId && (
        <span onClick={_open} className={className} title={title}>
          {children || (
            <IconButton className='c-inherit'>
              <i className='material-icons c-inherit'>edit</i>
            </IconButton>
          )}
        </span>
      )}
      {entity.id && (
        <Dialog
          open={open}
          onClose={_close}
          PaperProps={{ style: { maxWidth: 640 } }}
          name={dialogId}
          title={CrunchbaseLib.isEnabled() && entity.crunchbaseOrganizationId ? (
            <>
              <div>
                Configure {entityModelNameLowerCase}: {entity.name}
              </div>
              <span className='line-clamp-no-hover f-sm c-black-medium'>
                Changes will be applied to this and future candidates linked to this {entityModelNameLowerCase}
              </span>
            </>
          ) : (
            <>
              <div>
                Configure {entityModelNameLowerCase}
              </div>
              {CrunchbaseLib.isEnabled() && (
                <span className='line-clamp-no-hover f-sm c-black-medium'>
                  Note: No Crunchbase organization is linked to this {entityModelNameLowerCase}. Changes will be applied to this and future candidates link to this {entityModelNameLowerCase}
                </span>
              )}
            </>
          )}
          content={
            <>
              {(entity.id) && (
                <div className='d-flex flex-align-left-top'>
                  <Avatar
                    src={CrunchbaseLib.isEnabled() && organization.logo_url}
                    className='mr-1 bg-cyan'
                  >
                    {Abbreviation(entity.name)}
                  </Avatar>
                  <div className='flex-1'>
                    <div className='d-flex flex-align-left-center'>
                      <div className='f-lg mr-1'>
                        {entity.name}
                      </div>
                      {CrunchbaseLib.isEnabled() && (
                        <>
                          {organization.id && <Avatar variant='small' src={CRUNCHBASE__FAVICON} className='icon16' />}
                          <div className='ml-auto d-flex flex-align-left-center'>
                            {entityId && (
                              <>
                                {organization?.cb_url && (
                                  <Button flat
                                    size='small'
                                    title='Open the crunchbase URL'
                                    onClick={(event) => Core.openPopUp(organization.cb_url, 1200)}
                                    className='tt-unset c-black-medium c-purple-hover px-2'
                                    endIcon={<i className='material-icons c-inherit'>open_in_new</i>}
                                  >
                                    Open in crunchbase
                                  </Button>
                                )}
                              </>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                    <div className='c-black-medium f-xs'>
                      {entity.description || organization.short_description}
                    </div>
                  </div>
                </div>
              )}
              {!entity.crunchbaseOrganizationId && (
                <Row>
                  <Col className='p-0 w-100'>
                    <label>Name</label>
                    <Input
                      value={entity.name}
                      onChange={event => {
                        entity.name = event.target.value;
                        _updateState({ entity });
                      }}
                    />
                  </Col>
                </Row>
              )}
              {!entity.crunchbaseOrganizationId && (
                <Row className='mt-3'>
                  <Col className='p-0 w-100'>
                    <label>Description</label>
                    <Input
                      value={entity.description}
                      onChange={event => {
                        entity.description = event.target.value;
                        _updateState({ entity });
                      }}
                    />
                  </Col>
                </Row>
              )}
              <Row className='mt-3'>
                <Col className='p-0 d-flex flex-column w-100'>
                  <div className='d-flex flex-align-left-center'>
                    <label className='mr-auto'>
                      Configure signals
                    </label>
                    {CrunchbaseLib.isEnabled() && (
                      <Button flat
                        size='small'
                        variant='text'
                        className='tt-unset c-black-medium c-purple-hover px-2'
                        endIcon={<i className='material-icons c-inherit'>replay</i>}
                        onClick={_regenerateSignals}
                      >
                        Regenerate CB derived {entityModelName} Signals
                      </Button>
                    )}
                  </div>
                  <div className='pt-2 pl-2'>
                    {Definition.getTags({
                      categoryKey: 'positiveSignals',
                      tagIds: isForCompany ? POSITIVE_COMPANY_SIGNAL_TAG_IDS : POSITIVE_SCHOOL_SIGNAL_TAG_IDS,
                      sortByArray: true
                    }).map(tag => {
                      let checked = entity.signalTags.positive.includes(tag.id);
                      return (
                        <Chip
                          key={`edit-entity-positive-signal-chip-${tag.id}-${tag.label}`}
                          label={tag.label}
                          icon={entity.signalTags.automated.positive.includes(tag.id) && (
                            <i className='material-icons' title='Automated signal'>info</i>
                          )}
                          size='small'
                          // className='mr-1 mb-1'
                          className={`mr-1 mb-1 ${checked ? 'bg-green-lighter c-black-medium' : 'bg-unset dashed c-gray-common'}`}
                          color={checked ? 'success' : 'default'}
                          onClick={() => {
                            if (checked) {
                              // toggle off
                              if (entity.signalTags.automated.positive.includes(tag.id)) {
                                entity.signalTags.exclusions.positive.push(tag.id);
                                mapUpdatedSet(entity.signalTags.exclusions);
                              }
                              entity.signalTags.curated.positive = entity.signalTags.curated.positive.filter(id => id !== tag.id);
                              mapUpdatedSet(entity.signalTags.curated);
                            }
                            else {
                              // toggle on
                              if (entity.signalTags.automated.positive.includes(tag.id)) {
                                entity.signalTags.exclusions.positive = entity.signalTags.exclusions.positive.filter(id => id !== tag.id);
                                mapUpdatedSet(entity.signalTags.exclusions);
                              }
                              else {
                                entity.signalTags.curated.positive.push(tag.id);
                                mapUpdatedSet(entity.signalTags.curated);
                              }
                            }
                            entity.signalTags = mapOrganizationSignals({
                              signals: entity.signalTags,
                              type: entityModelName
                            });
                            _updateState({ entity });
                          }}
                        />
                      );
                    })}
                  </div>
                  <div className='pt-2 pl-2'>
                    {Definition.getTags({
                      categoryKey: 'negativeSignals',
                      tagIds: isForCompany ? NEGATIVE_COMPANY_SIGNAL_TAG_IDS : NEGATIVE_SCHOOL_SIGNAL_TAG_IDS,
                      sortByArray: true
                    }).map(tag => {
                      let checked = entity.signalTags.negative.includes(tag.id);
                      return (
                        <Chip
                          key={`edit-entity-negative-signal-chip-${tag.id}-${tag.label}`}
                          label={tag.label}
                          icon={entity.signalTags.automated.negative.includes(tag.id) && (
                            <i className='material-icons' title='Automated signal'>info</i>
                          )}
                          size='small'
                          className={`mr-1 mb-1 ${checked ? 'bg-red-lighter c-black-medium' : 'bg-unset dashed c-gray-common'}`}
                          color={checked ? 'error' : 'default'}
                          onClick={() => {
                            if (checked) {
                              // toggle off
                              if (entity.signalTags.automated.negative.includes(tag.id)) {
                                entity.signalTags.exclusions.negative.push(tag.id);
                                mapUpdatedSet(entity.signalTags.exclusions);
                              }
                              entity.signalTags.curated.negative = entity.signalTags.curated.negative.filter(id => id !== tag.id);
                              mapUpdatedSet(entity.signalTags.curated);
                            }
                            else {
                              // toggle on
                              if (entity.signalTags.automated.negative.includes(tag.id)) {
                                entity.signalTags.exclusions.negative = entity.signalTags.exclusions.negative.filter(id => id !== tag.id);
                                mapUpdatedSet(entity.signalTags.exclusions);
                              }
                              else {
                                entity.signalTags.curated.negative.push(tag.id);
                                mapUpdatedSet(entity.signalTags.curated);
                              }
                            }
                            entity.signalTags = mapOrganizationSignals({
                              signals: entity.signalTags,
                              type: entityModelName
                            });
                            _updateState({ entity });
                          }}
                        />
                      );
                    })}
                  </div>
                </Col>
              </Row>
              <Row className='mt-3'>
                <Col className='p-0 d-flex flex-column w-100'>
                  <label>Alternative Names</label>
                  <Select
                    mode='tags'
                    value={entity.alternativeNames}
                    onChange={value => {
                      entity.alternativeNames = value
                      _updateState({ entity });
                    }}
                  />
                </Col>
              </Row>
              {CrunchbaseLib.isEnabled() && (
                <Row className='mt-3'>
                  <Col className='p-0 w-100'>
                    <div className='d-flex'>
                      <label>Edit crunchbase organization linkage</label>
                      {organization.id && (
                        <Button flat
                          size='small'
                          title='Open the crunchbase URL'
                          onClick={async event => {
                            Core.showConfirm({
                              title: 'Warning',
                              content: (
                                <Box column w100>
                                  <div>
                                    This {entityModelNameLowerCase} will be unlinked and all signals associated with this {entityModelNameLowerCase} will be cleared
                                  </div>
                                  <div>
                                    Signals for other candidates linked to this {entityModelNameLowerCase} will not be changed until their candidate record is edited again
                                  </div>
                                </Box>
                              ),
                              async onAccept() {
                                entity.crunchbaseOrganizationId = null;
                                if (entity.name) { await _fetchSuggestions({ name: entity.name }); }
                                await _updateState({ entity, organization: {}, wipeoutOrganization: true });
                                await _regenerateSignals();
                              },
                              onAcceptLabel: 'Confirm remove'
                            });
                          }}
                          className='tt-unset c-black-medium c-purple-hover px-2 ml-auto'
                          endIcon={<i className='material-icons c-inherit'>link_off</i>}
                        >
                          Unlink crunchbase organization
                        </Button>
                      )}
                    </div>
                    <SuggestionList
                      title='Suggestions'
                      placeholder='Type or select an organization'
                      // DATA to be used as suggested options
                      data={suggestions}
                      // SELECTED option id
                      value={selected}
                      // DEFAULT search value - this input updates the search value -
                      defaultSearch={__defaultSearch}
                      defaultIcon={
                        wipeoutSearch
                          ? null
                          : !!organization.id && evalSameName(organization.name, __defaultSearch)
                            ? CRUNCHBASE__FAVICON
                            : !!entity.id && evalSameName(entity.name, __defaultSearch)
                              ? 'https://go10x10.com/favicon.ico'
                              : null
                      }
                      // SHOW a loader if date is fetching and list is empty
                      loading={fetchingSuggestions}
                      // ON render option
                      optionRender={CrunchbaseSuggestedOption}
                      // ON (pick one option | keydown enter | blur component)
                      onSelect={async ({ selected, option, search: _search }) => {
                        if (selected) {
                          await _updateState({ search, wipeoutSearch: false });
                          let newCBO = await CrunchbaseLib.get({
                            where: { id: selected }, limit: 1
                          }).then(organizations => organizations[0])
                            .catch(_onError);
                          let entities = Arr(
                            await (isForCompany ? Company : School).get({
                              where: { "crunchbaseOrganizationId": selected }
                            })
                          ).filter(c => c.id !== entityId);
                          if (!!entities.length) {
                            Core.showWarning(
                              <div className='flex-column'>
                                {`The selected Crunchbase organization is already associated with a ${entityModelNameLowerCase}. Please "Cancel" configure ${entityModelNameLowerCase} and go back to Edit Candidate ${labelSectionTitle} to select the correct ${entityModelNameLowerCase}`}
                              </div>
                            );
                          }
                          else {
                            Core.showConfirm({
                              title: 'Warning',
                              message: (
                                <div className='flex-column'>
                                  <div>
                                    Candidates' links to this {entityModelNameLowerCase} will be changed. Undo is not supported
                                  </div>
                                  {organization.id && (
                                    <div className='d-flex flex-align-left-center'>
                                      <div className='mr-1'>From:</div>
                                      {organization.logo_url && <Avatar src={organization.logo_url} className='mr-1 icon24'>{Abbreviation(organization.name)}</Avatar>}
                                      {organization.name} - will be deleted
                                    </div>
                                  )}
                                  <div className='d-flex flex-align-left-center'>
                                    <div className='mr-1'>To:</div>
                                    {newCBO?.logo_url && <Avatar src={newCBO.logo_url} className='mr-1 icon24'>{Abbreviation(newCBO?.name)}</Avatar>}
                                    {newCBO?.name} - signals will be generated from Crunchbase data
                                  </div>
                                </div>
                              ),
                              async onAccept() {
                                entity.crunchbaseOrganizationId = selected;
                                await _updateState({
                                  selected,
                                  option,
                                  search: _search,
                                  entity,
                                  wipeoutOrganization: false
                                });
                                await _fetchOrganization();
                                await _regenerateSignals();

                                // regenerate signals is fetching entity from DB and it is overwriting new crunchbaseOrganizationId selected, so we need set it back again.
                                entity.crunchbaseOrganizationId = selected;
                                await _updateState({ entity });
                              },
                              onAcceptLabel: 'Confirm Change'
                            });
                          }
                        }
                      }}
                      onCloseList={async (search) => {

                      }}
                      // ON search fetch new suggestions
                      onSearch={async (_search = '') => {
                        search = _search;
                        await _updateState({ search, wipeoutSearch: !search.length });
                        console.debug('ON_SEARCH', search);
                        if (fetchingSuggestions) { return; }
                        if (search) { _fetchSuggestions({ name: search }); }
                        // ON clear search
                        else { }
                      }}
                      onVisible={async (search) => {
                        console.debug('ON_VISIBLE', search);
                        search = search || __defaultSearch;
                        await _updateState({ search, wipeoutSearch: !search.length });
                        if (search) { _fetchSuggestions({ name: search }); }
                      }}
                      focusOnLoaded={open}
                    />
                  </Col>
                </Row>
              )}
              <div className='c-black-medium f-sm mt-3 d-flex flex-align-right-center' style={{ marginBottom: '-1rem' }}>
                {entity.updatedAt && `Updated at ${moment(entity.updatedAt).format('M/D/YYYY')}`}
                {account.id && ` by ${account._name}`}
              </div>
            </>
          }
          actionBar={
            <Button flat
              title={entity.name
                ? `Delete (${entity.name}) from our DB`
                : `Delete ${entityModelNameLowerCase}`
              }
              onClick={_delete}
              size='small'
              className='c-black-medium c-purple-hover tt-unset mr-auto'
              startIcon={<i className='material-icons c-inherit'>delete</i>}
            >
              Delete {entityModelNameLowerCase}
            </Button>
          }
          actions={[
            <Button
              color='secondary'
              variant='outlined'
              onClick={_close}
              className='min-w-120'
            >
              Cancel
            </Button>,
            <Button
              color='primary'
              variant='contained'
              onClick={_save}
              className='min-w-120'
            >
              Save
            </Button>
          ]}
        />
      )}
    </>
  )
}
