
const SALARY_CURRENCIES = [
  {
    id: 'USD',
    label: 'USD',
  },
  {
    id: 'CAD',
    label: 'CAD',
  },
  {
    id: 'EUR',
    label: 'EUR',
  },
  {
    id: 'Other',
    label: 'Other',
  },
];
export default SALARY_CURRENCIES;
