import Slide from "@mui/material/Slide";
import _, { debounce } from "lodash";
import dig from "object-dig";
import queryString from "query-string";
import {
  Component
} from "react";
import {
  withTranslation
} from "react-i18next";
import {
  Arr,
  join,
  sanitizeArr
} from '../../lib/Array.lib';
import Candidate from "../../lib/Candidate";
import Core from "../../lib/Core";
import {
  ACCOUNT_ACTION__EDIT_CANDIDATE,
  ACCOUNT_ACTION__EDIT_JOB
} from '../../lib/Definition';
import Engagement from "../../lib/Engagement";
import Job from "../../lib/Job";
import SourceListHash from "../../lib/SourceListHash";
import Store from "../../lib/Store";
import {
  getLocation,
  getSearch
} from '../../lib/URL.lib';
import copyHtml from "../../lib/tools/copyHtml";
import getJobSourceListApis from "../../lib/tools/getJobSourceListApis";
import onReady from "../../lib/tools/onReady";
import EditJobSourceList from "../Accounts/Admin/EditJobSourceList";
import ConfirmDialog from "../Dialogs/ConfirmDialog";
import ReactTable from "../Home/ReactTable";
import {
  configJobs
} from "../Home/configJobs";
import {
  THEME__COLOR__SECONDARY
} from '../Layout/Libraries/Theme.lib';
import Autocomplete from '../Layout/Wrappers/Autocomplete';
import Box from '../Layout/Wrappers/Box';
import Button from '../Layout/Wrappers/Button';
import Dialog from '../Layout/Wrappers/Dialog';
import FloatingActionButton from '../Layout/Wrappers/FloatingActionButton';
import Menu from '../Layout/Wrappers/Menu';
import NavLink from '../Layout/Wrappers/NavLink';
import Navigate from '../Layout/Wrappers/Navigate';
import Snackbar from '../Layout/Wrappers/Snackbar';
import { Label } from '../Layout/Wrappers/Typography';
import List from "../List/ListNoPaging";
import JobCard from "./Card/JobCard";
import JobSourceList from "./JobSourceList.lib";

/* METHODS ==================================== */

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class Jobs extends Component {
  data;
  constructor() {
    super(...arguments);
    this.name = "Jobs";

    this.state = {
      showAll: false,
      snackBarMessage: "",
      snackBarOpen: false,
      selected: false,
      isSubmittingCandidate: false,
      isSubmittingCandidateChoice: false,
      candidates: [],
      submittingCandidatePicked: null,
      params: queryString.parse(getSearch()),
      displayEngagements: Core.isAdminOrCoordinator(),
      hasEngagementsFetched: false,
      allJobs: [],
      recruiterJobIds: [],
      collectedSourceLists: [],
      jobIdHashedWithAccount: {},
    };

    Store.set("path", getLocation());
    this.reloadData = (ev) => this.loadData();
    this.loadData();
  }

  componentDidMount() {
    this.getRecruiterJob();
  }

  getRecruiterJob = () => {
    if (Core.isRecruiter()) {
      getJobSourceListApis.getJobIdsByRecruiter((recruiterJobIds) =>
        this.setState({
          recruiterJobIds: recruiterJobIds.map((job) => ({
            jobId: job.jobId,
            startDate: job.startDate,
          })),
        })
      );
    }
  };

  setJobIdsHash = () => {
    let hash = {};
    hash = SourceListHash(this.state.collectedSourceLists, "jobId");

    this.setState({ jobIdHashedWithAccount: hash }, () => {
      this.List.setItems(this.state.filteredItems);
    });
  };

  getRecruiterDetailsThroughJobs = debounce(() => {
    const { allJobs } = this.state;
    const onlyIds = allJobs.map((job) => job.id);

    if (Core.isAdmin() && !this.state.collectedSourceLists.length) {
      let chunked = _.chunk(onlyIds, 200);
      chunked.forEach((subArray, index) => {
        getJobSourceListApis.getRecruiterDetailByJobIds(
          "jobId",
          subArray,
          (response) => {
            /**
             * "Job source list shows both active and inactive sourcing recruiters.
             * Should only show active (only has a start date)."
             * @see story 2534 task 10
             */
            const filteredActiveSource = [...response].filter(n => !n.endDate);
            this.setState(
              {
                collectedSourceLists: [
                  ...this.state.collectedSourceLists,
                  ...filteredActiveSource,
                ],
              },
              () => {
                this.setJobIdsHash();
              }
            );
          }
        );
      });
    }
  });

  loadData() {

    const { displayEngagements, hasEngagementsFetched } = this.state;
    if (!!hasEngagementsFetched) {
      this.List.setItems(this.state.filteredItems);
      return;
    }

    let opts = { commonQuery: { engagements: displayEngagements } };

    if (Core.isAdminOrCoordinator()) {
      Job.cleanCache();
      this.setState({ loadingWithEngagements: true });
      Job.getAll(
        (jobs) => onReady(this, "filterControl").then((em) => {
          this.filterControl.setItems(jobs, /^Active$/);
          this.setState(
            {
              allJobs: jobs.filter((job) => job.state === 1),
              loadingWithEngagements: false,
              hasEngagementsFetched: displayEngagements,
            },
            () => {
              this.getRecruiterDetailsThroughJobs();
            }
          );
        }),
        {},
        opts
      );
    }
    else {
      Job.getActives(
        (jobs) =>
          onReady(this, "filterControl").then((em) => {
            this.filterControl.setItems(jobs, /^Active$/);
            this.setState(
              { allJobs: jobs, hasEngagementsFetched: displayEngagements },
              () => {
                this.getRecruiterDetailsThroughJobs();
              }
            );
          }),
        opts
      );
    }
  }

  loadCandidates = (keyword, callback) => {
    setTimeout(() => {
      const ilikeStruct = { like: `.*${keyword}.*`, options: "i" };

      Candidate.getWhere(
        {
          or: [
            { firstName: ilikeStruct },
            { lastName: ilikeStruct },
            { email: ilikeStruct },
            { linkedInURL: ilikeStruct },
            { gitHubURL: ilikeStruct },
          ],
        },
        (candidates) => {
          const formattedCandos = candidates.map((el) => {
            let label = `${el._name} | ${el.email} | ${el.phone}`;
            return { value: el.id, label };
          });
          console.log({ formattedCandos });
          callback(formattedCandos);
        },
        { limit: 50, fields: ["id", "firstName", "lastName", "email", "phone"] }
      );
    });
  };

  showMessage = (msg) => {
    this.setState({
      snackBarMessage: msg,
      snackBarOpen: true,
    });
  };
  hideMessage = () => {
    this.setState({
      snackBarMessage: "",
      snackBarOpen: false,
    });
  };
  bulkCopy(type) {
    const t = this.props.t;
    if (!this.state.selected || this.state.selected.length === 0) {
      Core.showWarning("Please select at least 1 job to copy. The selected jobs’ description will be copied to paste elsewhere");
      return;
    }
    let body = "";
    Core.log({ sel: this.List.state.selected });
    this.List.selected.forEach((state) => {
      const card = this.List.cards[state.id];
      if (card && card.getPreview) {
        body += card.getPreview(type);
        body += "<br/><hr/>";
      }
    });
    Core.log({ length: body.length });
    copyHtml(`
      ${body}
    `)
      .then((em) => {
        Core.log("Copy email command was successful");
        Core.showSuccess(t('jobs.copyButton.successful'));
      })
      .catch((ex) => {
        Core.log("Oops, unable to copy");
        Core.showError("Fail copy!");
      });
  }

  dialogClose = (state) => () => {
    this.setState(state);
  };

  optsCandidateDropdown = (inputValue, callback) => {
    this.loadCandidates(inputValue, callback);
  };

  handlerSubmittingCandidatePicked = (selected) => {
    this.setState({ submittingCandidatePicked: selected });
  };

  submittingCandidateEdit = () => {
    this.closeAndResetCandChoice();
    this.setState({ isSubmittingCandidate: true });
  };

  //may be used when clicked in card and need to show data in expanded
  fetchEngagements = (cb) => {
    const { selected } = this.state;
    let jobEngagements = [];
    let count = 0;
    selected.forEach((job) => {
      Engagement.getWhere({ jobId: job.id }, (resp) => {
        jobEngagements.push(resp);
        count++;
        if (count === selected.length) {
          !!cb && cb(jobEngagements);
        }
      }, {}, false, { source: `jobs_v3__match_new__candidate_pipe_submission_history` });
    });
  };

  recruiterJobs = () => {
    const { recruiterJobIds } = this.state;
    const recruiterOnlyIds = recruiterJobIds.map((job) => job.jobId);
    const { allJobs } = this.state;
    let filteredJobItems = [],
      restoredAddedJobsWithDates = [];

    if (!!allJobs.length) {
      filteredJobItems = allJobs.map((d) => ({ id: d.id, label: d._name }));

      return (
        <EditJobSourceList
          accountJobSourceList={filteredJobItems}
          title={"EDIT MY SOURCING"}
          values={recruiterOnlyIds}
          addButtonClass={"list-add-new chip-button m-0 mb-1 mr-1"}
          labelColor={"#ffffff"}
          heading="Add or delete job to source"
          onChange={(jobs, jobObj) => {
            JobSourceList.process(recruiterOnlyIds, jobs);
            JobSourceList.sendEmailManager(
              recruiterOnlyIds,
              jobs,
              filteredJobItems,
              recruiterJobIds
            );
            restoredAddedJobsWithDates = JobSourceList.restoreAddedJobsWithDates(
              recruiterJobIds,
              jobs
            );
            this.setState(
              { recruiterJobIds: restoredAddedJobsWithDates },
              () => {
                this.List.setItems(this.state.allJobs);
              }
            );
          }}
        />
      );
    }

    return null;
  };

  dialogCreateCandChoice = () => {

    const isShowingCandDropdown = (
      this.state.submittingCandidateChoice === "ex" &&
      this.state.candidates.length >= 0
    );

    const isCandSelected = !!this.state.submittingCandidatePicked;

    const _setSelected = (submittingCandidatePicked) => {
      this.setState({
        submittingCandidatePicked
      });
    };

    const _renderLabel = ({ _name, email, phone }) => join(
      [_name, email, phone],
      ' | '
    )

    const _fetchOptions = async (search = '') => {
      // @note  Alway must be something to search, default: "a".
      const iLikeStruct = {
        like: `.*${search || 'a'}.*`,
        options: "i"
      };
      return Candidate.getWhere(
        {
          or: [
            { firstName: iLikeStruct },
            { lastName: iLikeStruct },
            { email: iLikeStruct },
            { phone: iLikeStruct },
            { linkedInURL: iLikeStruct },
            { gitHubURL: iLikeStruct },
          ],
        },
        null,
        {
          limit: 50,
          fields: [
            'id',
            'firstName',
            'lastName',
            'email',
            'phone',
          ]
        }
      ).then(
        /** 
         * @note
         * We must map the model to avoid cycled loops.
         */
        (candidates = []) => candidates.map(
          (
            { id, _name, email, phone }
          ) => (
            { id, _name, email, phone }
          )
        )
      );
    }

    return (
      <Dialog keepMounted
        open={this.state.isSubmittingCandidateChoice}
        onClose={this.handleClose}
        TransitionComponent={Transition}
        title={isShowingCandDropdown && 'Enter Existing Candidate Name or Email'}
        content={
          <>

            <Box column alignCenter
              acl={!isShowingCandDropdown}
            >
              <Button w100
                label={`Create New Candidate?`}
                onClick={this.handlerSubmitCandidateOpt("new")}
              />
              <Label w100 alignCenter className='my-05'>
                OR
              </Label>
              <Button w100
                label={`Pick from Existing Ones?`}
                onClick={this.handlerSubmitCandidateOpt("ex")}
              />
            </Box>

            <Autocomplete autoFocus acl={isShowingCandDropdown}
              value={this.state.submittingCandidatePicked}
              onSelect={_setSelected}
              renderLabel={_renderLabel}
              asyncOptions={_fetchOptions}
            />

          </>
        }
        actions={
          sanitizeArr([
            <Button outlined minW120
              label={`Cancel`}
              onClick={this.closeAndResetCandChoice}
            />,
            isShowingCandDropdown && isCandSelected && (
              <Button primary minW120
                label={`SUBMIT CANDIDATE`}
                onClick={this.submittingCandidateEdit}
              />
            )
          ])
        }
        paperStyle={{ width: 640 }}
      />
    );
  };

  closeAndResetCandChoice = () => {
    this.dialogClose({
      isSubmittingCandidateChoice: false,
      submittingCandidateChoice: null,
      isSubmittingCandidate: false,
    })();
  };

  dialogSubmitCandidate = () => {
    if (!this.state.isSubmittingCandidate) {
      return;
    }
    let defaultValues = {};
    let permittedJobs = this.state.selected
      ? this.state.selected.map((o) => o.id)
      : [];
    let roles = this.state.selected
      ? this.state.selected.map((o) => o.role)
      : [];

    if (permittedJobs.length) {
      defaultValues["jobsPermitted"] = permittedJobs;
    }

    if (roles.length) {
      let set = new Set(roles);
      defaultValues["roles"] = Array.from(set);
    }

    let candidateId = dig(this.state, "submittingCandidatePicked", "value");
    let path = "";
    let queryString =
      "source=jobs&defaultRoles=" +
      roles.join(",") +
      "&" +
      "defaultJobs=" +
      permittedJobs.join(",");

    if (candidateId) {
      path = `/candidate/edit/${candidateId}?${queryString}`;
    } else {
      path = `/candidate/create?${queryString}`;
    }

    return <Navigate to={path} />;
  };

  handlerSubmitCandidate = () => {
    if (this.state.selected.length > 0) {
      this.setState({ isSubmittingCandidateChoice: true });
    } else {
      this.setState({ isSubmittingCandidate: true });
    }
  };

  handlerSubmitCandidateOpt = (val) => () => {
    this.setState({
      submittingCandidateChoice: val,
      isSubmittingCandidate: val === "new",
    });
  };

  handleClickBulkCopy = (event) => {
    this.setState({ bulkCopyAnchorEl: event.currentTarget });
  };

  handleCloseBulkCopy = (type) => (ev) => {
    this.setState({ bulkCopyAnchorEl: null });
    !!type && this.bulkCopy(type);
  };

  render() {
    const { t } = this.props;
    const {
      recruiterJobIds,
      jobIdHashedWithAccount,
      displayEngagements,
    } = this.state;
    const {
      List: ListController = {}
    } = this;
    const {
      selected: selectedJobs = []
    } = ListController;
    const isCopyJobsDisabled = !selectedJobs.length;
    console.debug('JobsV3', this.state);
    return (
      <>


        <div style={{ height: 'auto' }}>
          <ReactTable
            {...this.props} // to pass the router context
            config={configJobs}
            onChange={data => {
              this.setState(
                {
                  allJobs: data
                },
                state => {
                  this.List.setItems(Arr(data));
                }
              );
            }}
            disableBodyRender
          />
        </div>

        <div className="d-flex flex-align-right-center pt-1 px-3">
          {Core.isRecruiter() && this.recruiterJobs()}
          {(!Core.isAdmin() || Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_CANDIDATE })) && (
            <Button flat
              label={
                !!this.state.selected.length
                  ? `Submit Candidate to ( ${this.state.selected.length} ) Jobs`
                  : "Submit new Candidate"
              }
              className="list-add-new mb-1 mr-1"
              onClick={this.handlerSubmitCandidate}
            />
          )}
          <div
            className="hint--left hint--rounded"
            aria-label={t('jobs.copyButton.ariaLabel.label')} l
          >
            <Button primary small
              label={`Copy Job(s)`}
              className="list-add-new mb-1 mr-1"
              disabled={isCopyJobsDisabled}
              onClick={this.handleClickBulkCopy}
            />
          </div>
          <Menu
            name='jobs_v3__bulk_copy'
            anchorEl={this.state.bulkCopyAnchorEl}
            open={Boolean(this.state.bulkCopyAnchorEl)}
            onClose={this.handleCloseBulkCopy()}
            options={[
              {
                id: 'long_jd',
                label: 'Long JD',
                onClick: this.handleCloseBulkCopy('large')
              },
              {
                id: 'short_jd',
                label: 'Short JD',
                onClick: this.handleCloseBulkCopy('short')
              }
            ]}
          />
          {Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_JOB }) && (
            <NavLink to="/job/create" className="ui-m-min">
              <Button flat label="+ New Job" className="list-add-new mb-1 mr-1" />
            </NavLink>
          )}
        </div>

        {this.dialogSubmitCandidate()}
        {this.state.selected && this.dialogCreateCandChoice()}

        <List
          className="p-0"
          ref={(List) => (this.List = List)}
          tabs={Job.listTabs}
          tab={Job.listTab}
          disableTabs={true}
          disableFooter
          name="Job"
          card={JobCard}
          params={this.state.params}
          parent={this}
          onCheck={(selected) => {
            this.setState({ selected });
          }}
          floatingButton={
            Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_JOB }) && (
              <NavLink className="ui-m-max" to={`/job/create`}>
                <FloatingActionButton
                  className="list-floating-button"
                  color={THEME__COLOR__SECONDARY}
                />
              </NavLink>
            )
          }
          extraInfo={{
            recruiterJobIds,
            jobIdHashedWithAccount,
            displayEngagements,
          }}
        />
        <Snackbar
          open={this.state.snackBarOpen}
          message={this.state.snackBarMessage}
          className="snack-bar"
          autoHideDuration={4000}
          onClose={this.hideMessage}
        />
        <ConfirmDialog
          ref={(self) => (this.ConfirmDialog = self)}
          title="Delete Job(s)?"
          message="This action can't be undone."
          actionLabel="Delete"
        />
      </>
    );
  }
}

export default withTranslation()(Jobs);
