import moment from 'moment';

export function toLinkedinDateString({ date, month, year, currentDate, currentYear } = {}) {
  // console.warn('toLinkedinDateString', { date, month, year, currentDate, currentYear });
  return (
    (
      currentDate
    ) ? (
      moment().format('MMM YYYY')
    ) : (
      currentYear
    ) ? (
      moment().format('YYYY')
    ) : (
      date
    ) ? (
      moment(date).format('MMM YYYY')
    ) : (
      year
    ) ? (
      (
        month
      ) ? (
        moment().year(year).month(month).format('MMM YYYY')
      ) : (
        moment().year(year).format('YYYY')
      )
    ) : (
      ''
    )
  );
}
