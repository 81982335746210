import {
  compile
} from 'handlebars';
import ReactDOMServer from "react-dom/server";
import {
  Arr,
  join,
  sanitizeArr
} from '../../lib/Array.lib';
import {
  jobLevels
} from '../../lib/Constants';
import Core from '../../lib/Core';
import Definition from '../../lib/Definition';
import LocationLib from '../../lib/DefinitionLocation.lib';
import {
  escapeRegExp
} from '../../lib/GenericTools.lib';
import {
  getHTMLLinkString
} from '../../lib/HTML.lib';
import {
  Obj
} from '../../lib/Object.lib';
import {
  POSITIVE_SCHOOL_SIGNAL_TAG_IDS
} from '../../lib/School';
import {
  Str,
  trim
} from '../../lib/String.lib';
import {
  mapCandidateTechSkills,
  mapCandidateVisa,
  mapMinimumSalary
} from '../../lib/models/candidate';
import {
  GH_RANK__A_PLUS,
  GH_RANK__A_P_PLUS,
  GH_RANK__S,
  GH_RANK__S_PLUS
} from '../../lib/services/GitHub/calculateRank.lib';
import githubGraphqlGetUser from '../../lib/services/GitHub/githubGraphqlGetUser';
import {
  TEMPLATE__CANDIDATE_SUBMISSION__CANDIDATE_NOTES
} from '../../lib/templates/CandidateSubmission.template';
import mapCandidateWorkExperience from './ResumeSubmission/mapCandidateWorkExperience.tool';

// if rename constant name, update the model comment of submissionIncludeFlags field
// [ 2023-01-06 ] once this code to staging becomes released, do not change the key:number pairs
export const CANDIDATE_SUMMARY__INCLUDE_FLAGS = {
  minimumSalary: 1,
  reasonsToLeaveLastJob: 2,
  explainRedFlags: 3,
  calendarBookingLink: 4,
  otherCompanyInterviews: 5,
  locationDetails: 6
};

const CACHE = {
  resumeTxtUrl: {},
  gitHubStats: {}
};

export default function getCandidateNotes({ candidate, job, onLoad = () => null, refresh = false, disclaimer = true }) {
  const _onLoad = compiledTemplate => setTimeout(() => onLoad(compiledTemplate));
  function _setPositiveSignals() {
    if (candidate.___positiveSignals && !refresh) { return; }
    let positiveSignalLabels = [];
    let { ranking } = candidate;
    ranking = ranking || [];

    if (!!candidate && !!candidate.positiveSignals.length) {
      let positiveSignalsSchool = [];
      let positiveSignalsXp = [];

      let idsFiltered = [];

      candidate.positiveSignals.forEach((id) => {
        if (!!candidate.schoolsLinkedIn.length) {
          candidate.schoolsLinkedIn
            .filter((school) => !!school.schoolRanking)
            .forEach((school) => {
              if (
                school.schoolRanking ===
                Definition.getLabel("positiveSignals", id)
              ) {
                idsFiltered.push(id);
                positiveSignalsSchool.push(
                  `${school.school} ( ${school.schoolRanking} )`
                );
              }
            });
        } else {
          const singleSchool = (ranking || []).find((rank) => {
            return rank.universityName === candidate.undergraduateSchool;
          });

          if (
            !!singleSchool &&
            !!singleSchool.schoolRating &&
            !!singleSchool.schoolRating.length &&
            Array.isArray(!!singleSchool.schoolRating[0].positiveSignalsTags) &&
            !!singleSchool.schoolRating[0].positiveSignalsTags.includes(
              Definition.getLabel("positiveSignals", id)
            )
          ) {
            idsFiltered.push(id);
            positiveSignalsSchool.push(
              `${candidate.undergraduateSchool} ( ${Definition.getLabel(
                "positiveSignals",
                id
              )} )`
            );
          }
        }

        if (!!candidate.jobsLinkedIn.length) {
          candidate.jobsLinkedIn
            .filter((job) => !!job.rankingMeta)
            .forEach((job) => {
              if (
                job.rankingMeta.InterviewBarRanking ===
                Definition.getLabel("positiveSignals", id) &&
                !idsFiltered.includes(id)
              ) {
                idsFiltered.push(id);
                positiveSignalsXp.push(
                  `${job.rankingMeta.CompanyName} ( ${job.rankingMeta.InterviewBarRanking} )`
                );
              }
            });
        }
      });

      let remainingIds = candidate.positiveSignals.filter(
        (el) => ![...idsFiltered].includes(el)
      );

      positiveSignalLabels = [
        ...positiveSignalsXp,
        ...Definition.getLabels("positiveSignals", remainingIds),
      ];
    }
    let workExperienceSignals = Arr(candidate.__workExperienceSignalsTags).map(({ label }) => label);
    positiveSignalLabels = join(positiveSignalLabels.filter(v => !workExperienceSignals.includes(v)));
    candidate.___positiveSignals = positiveSignalLabels;
  };
  (function setResumeTechSkills() {
    if (candidate.___resumeTechSkills && !refresh) { return; }
    if (
      !!candidate.resumeTxtUrl &&
      !!job &&
      !!candidate.technicalSkills
    ) {
      const onGetResumeTxtUrl = (resumeText) => {
        CACHE.resumeTxtUrl[candidate.resumeTxtUrl] = resumeText;
        candidate.___resumeTechSkills = join(
          resumeTextMatchSkills({
            resumeText,
            employerSkillIds: Obj(job.employer).technicalSkills,
            jobSkillIds: job.technicalSkills
          }).map(tag => tag.label)
        );
        _onLoad(compileTemplate());
        CACHE.fetchingResumeTxtUrl = false;
      }
      let resumeTxtUrl = CACHE.resumeTxtUrl[candidate.resumeTxtUrl];
      if (resumeTxtUrl) {
        onGetResumeTxtUrl(resumeTxtUrl);
      }
      else if (!CACHE.fetchingResumeTxtUrl) {
        CACHE.fetchingResumeTxtUrl = true;
        fetch(candidate.resumeTxtUrl).then((responseText) => {
          responseText.text().then(onGetResumeTxtUrl);
        }).catch(error => {
          CACHE.fetchingResumeTxtUrl = false;
          console.debug(error);
        });
      }
    }
  })();
  (function setYearsOfExperienceLabel() {
    if (candidate.yearsOfExperience > 10) {
      candidate.___yearsOfExperience = '<b>10+ years</b>&nbsp;experience';
    }
    else if (candidate.yearsOfExperience >= 0) {
      candidate.___yearsOfExperience = candidate.yearsOfExperience ? `<b>${candidate.yearsOfExperience} years</b>&nbsp;experience` : '';
    }
  })();
  (function setLevel() {
    const getJobLevelConstantsKeys = jobLevels.map((level) => level.key);
    if (!!job && getJobLevelConstantsKeys.includes(job.level)) {
      candidate.___level = `Level : ${job._level}`;
    }
  })();
  (async function setGitHubStats() {
    if ((candidate.___gitHubStats && !refresh) || CACHE.fetchingGitHubStats) { return; }
    let gitHubStats = CACHE.gitHubStats[candidate.gitHubURL];
    const _onGetGitHubStats = (gitHubStats = {}) => {
      CACHE.gitHubStats[candidate.gitHubURL] = gitHubStats;
      let { rank = {}, submissionNotesHtml } = gitHubStats;
      let { level } = rank;
      let validRanks = [GH_RANK__S_PLUS, GH_RANK__S, GH_RANK__A_P_PLUS, GH_RANK__A_PLUS];
      if (validRanks.includes(level)) {
        candidate.___gitHubStats = ReactDOMServer.renderToString(submissionNotesHtml || '');
      }
      else {
        candidate.___gitHubStats = '';
      }
      _onLoad(compileTemplate());
      CACHE.fetchingGitHubStats = false;
    }
    if (gitHubStats) {
      _onGetGitHubStats(gitHubStats);
    }
    else {
      CACHE.fetchingGitHubStats = true;
      return githubGraphqlGetUser({
        gitHubURL: candidate.gitHubURL,
        candidate
      }).catch(error => {
        _onGetGitHubStats();
        console.debug(error);
      }).then(_onGetGitHubStats);
    }
  })();
  (function setLocations() {
    const {
      inOfficeRemoteFlags = [],
      officeLocations = [],
      candidateLocations = [],
    } = candidate;
    candidate.___inOfficeRemoteFlagsLabels = Definition.getLabels(
      'inOfficeRemote',
      inOfficeRemoteFlags.sort()
    ).join(', ');
    candidate.___officeLocationsLabels = LocationLib.getLocationsString({
      locations: officeLocations,
      template: '{{LOCATION}}, {{ANCEST}}'
    });
    candidate.___candidateLocationLabels = LocationLib.getLocationsString({
      locations: candidateLocations,
      template: '{{LOCATION}}, {{ANCEST}}'
    });
  })();
  (function setCollegeData() {
    if (candidate.___normalCollegeFlow && !refresh) { return; }
    let data = "";
    let schoolSignals = Definition.getLabels('positiveSignals',
      candidate.positiveSignals.filter(id => [...POSITIVE_SCHOOL_SIGNAL_TAG_IDS].includes(id))
    ).join(', ') || '';
    let label = `${(
      candidate._undergraduateDegree || ''
    )}${(
      candidate.undergraduateMajor ? ` ${candidate.undergraduateMajor}` : ''
    )}${(
      candidate.undergraduateSchool ? ` @ ${candidate.undergraduateSchool}` : ''
    )}${(
      schoolSignals ? ` | <b>${schoolSignals}</b>` : ''
    )}`.trim();
    data += label ? `<li>${label}</li>` : '';
    candidate.___normalCollegeFlow = data;
  })();
  (function setVisa() {
    candidate.___isNotVisaUnknown = true;
    if (!candidate.visa) {
      candidate.___isNotVisaUnknown = false;
    }
  })();
  function compileTemplate() {
    const outputHtml = compile(TEMPLATE__CANDIDATE_SUBMISSION__CANDIDATE_NOTES)({
      candidate,
      CANDIDATE__FIRSTNAME: candidate.firstName || 'Candidate',
      CANDIDATE__NICKNAME: Str(candidate.nickName),
      CANDIDATE__FLAGS__IN_OFFICE_REMOTE: candidate.___inOfficeRemoteFlagsLabels,
      CANDIDATE__LOCATION_DETAILS: trim(
        candidate.locationDetails
      ).replace(/\.$/, ''),
      CANDIDATE__PREFERRED_LOCATIONS: candidate.___candidateLocationLabels,
      POSITIVE_SIGNALS: Core.isAdmin() ? candidate.___positiveSignals : '',
      WORK_EXPERIENCE: Core.isAdmin() ? candidate.__workExperienceForSubmissionNotes : '',
      TECH_SKILLS: candidate.___resumeTechSkills ? `<li>Tech Skills:&nbsp;${candidate.___resumeTechSkills}</li>` : '',
      YEARS_OF_EXPERIENCE: candidate.___yearsOfExperience ? `<li>${candidate.___yearsOfExperience}</li>` : '',
      MOST_RECENT: join([candidate.currentEmployer, candidate.currentTitle], ' - '),
      LEVEL: candidate.___level ? `<li>${candidate.___level}</li>` : '',
      GITHUB_STATS: (
        candidate.___gitHubStats
          ? `<li>${candidate.___gitHubStats}</li>`
          : candidate.gitHubURL
            ? `<li>${getHTMLLinkString({ url: candidate.gitHubURL, dropProtocol: true })}</li>`
            : ''
      ),
      STACK_OVERFLOW: (
        candidate.stackoverflowUrl
          ? `<li>${getHTMLLinkString({ url: candidate.stackoverflowUrl, dropProtocol: true })}</li>`
          : ''
      ),
      CANDIDATE__TECH_SKILLS: mapCandidateTechSkills(candidate),
      INCLUDE_MINIMUM_SALARY: (
        candidate.minimumSalary &&
        candidate.submissionIncludeFlags.includes(CANDIDATE_SUMMARY__INCLUDE_FLAGS.minimumSalary)
      ),
      MINIMUM_SALARY: mapMinimumSalary(candidate).replace(' ', '+ '),
      SALARY_CONFIDENCE: Definition.getLabel('salaryConfidence', candidate.salaryConfidence),
      VISA: mapCandidateVisa(candidate),
      INCLUDE_REASONS_LEAVE: (
        candidate.reasonsToLeaveLastJob &&
        candidate.submissionIncludeFlags.includes(CANDIDATE_SUMMARY__INCLUDE_FLAGS.reasonsToLeaveLastJob)
      ),
      INCLUDE_EXPLAIN_RED_FLAGS: (
        candidate.explainRedFlags &&
        candidate.submissionIncludeFlags.includes(CANDIDATE_SUMMARY__INCLUDE_FLAGS.explainRedFlags)
      ),
      INCLUDE_CALENDAR_BOOKING: (
        candidate.calendarBookingLink &&
        candidate.submissionIncludeFlags.includes(CANDIDATE_SUMMARY__INCLUDE_FLAGS.calendarBookingLink)
      ),
      INCLUDE_COMPANY_INTERVIEWS: (
        candidate.otherCompanyInterviews &&
        candidate.submissionIncludeFlags.includes(CANDIDATE_SUMMARY__INCLUDE_FLAGS.otherCompanyInterviews)
      ),
      INCLUDE_LOCATION_DETAILS: (
        candidate.locationDetails &&
        candidate.submissionIncludeFlags.includes(CANDIDATE_SUMMARY__INCLUDE_FLAGS.locationDetails)
      )
    });
    // console.debug('outputHtml', candidate, outputHtml);
    return outputHtml;
  }

  (async () => {
    await mapCandidateWorkExperience({ candidate, disclaimer });
    _setPositiveSignals();
    _onLoad(compileTemplate());
  })();

  let result = _onLoad(compileTemplate());
  return result;
}

/**
 * 
 * @param {object} options 
 * @param {object} options.resumeText
 * @param {object} options.employerSkillIds
 * @param {object} options.jobSkillIds
 * @returns {object[]}
 */
export function resumeTextMatchSkills(options) {
  let { resumeText, employerSkillIds, jobSkillIds } = options;
  resumeText = Str(resumeText);
  employerSkillIds = Arr(employerSkillIds);
  jobSkillIds = Arr(jobSkillIds);
  let tagIds = sanitizeArr([
    ...employerSkillIds,
    ...jobSkillIds,
  ]);
  let matchedSkills = (!!tagIds.length
    ? Definition.getTags({
      categoryKey: 'technicalSkills',
      tagIds
    }).filter(tag => matchSkillWithText(tag.label, resumeText))
    : []
  );
  return matchedSkills;
}

export const matchSkillWithText = (label, text) => {
  label = escapeRegExp(label);
  const skillRegx = new RegExp(`\\b${label}\\b|^${label}\\b|^${label},\\w`, 'gi');
  return !!skillRegx.test(text);
}
